<template>
  <div id="adminPanel" style="width: 100%">
    <v-container fluid 
    class="scrollable"
      style="
        margin: 0 auto;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        width: 100%;
            overscroll-behavior: auto !important;
      ">
      <v-row class="mb-0" style="flex-grow: 0">
        <v-col :cols="12" style="display: flex" class="pa-0">
          <v-container fluid class="pa-0">
            <v-row class="ma-0">
              <v-col :cols="6" class="pa-0 pr-1">
                <v-card flat>
            <v-card-title class="encodeFont"
              ><v-icon class="mr-2">mdi-human</v-icon> Redakteursliste</v-card-title
            >
          </v-card>
        </v-col>
        <v-spacer />
        <v-col cols="auto" class="pa-0 pl-3">
          <v-btn
            @click="b_showEditorEdit = true"
            large
            block
            elevation="0"
            class="primaryOnWhiteButton pa-0"
            style="width: 64px"
            ><v-icon> mdi-plus</v-icon>
          </v-btn>
        </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>

      <v-row style="flex-grow: 0">
        <v-col class="pb-0 pl-0 mt-2">
          <h2 class="encodeFont">Offene Einladungen</h2>
        </v-col>
      </v-row>
      <v-row style="flex-grow: 0" v-if="editorRequests.length > 0">
        <v-col
          v-for="(er, index) in editorRequests"
          :key="er.id"
          :class="{
            'pr-2': index % 2 == 0 && !isMobile ? true : false,
            'pl-2': index % 2 != 0 && !isMobile ? true : false,
            'pr-0': index % 2 != 0 && !isMobile ? true : false,
            'pl-0': index % 2 == 0 && !isMobile ? true : false,
            'px-0': isMobile,
          }"
          :cols="isMobile ? 12 : 6"
        >
          <v-card flat class="pa-2">
            <v-container fluid>
              <v-row class="ma-0 pa-0">
                <v-col class="ma-0 pa-0 pb-2">
                  <h2 class="encodeFont">
                    {{
                      getReadableSalutation(er.salutation) +
                      " " +
                      er.first_name +
                      " " +
                      er.last_name
                    }}
                  </h2>
                </v-col>
                <v-spacer />
                <v-col cols="auto" class="ma-0 pa-0 pb-2">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        @click="showResendWarning(er)"
                        v-bind="attrs"
                        v-on="on"
                        class="mr-2"
                        color="primary"
                        >mdi-email-fast
                      </v-icon>
                    </template>
                    <span>Registrierungsmail erneut versenden</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        @click="showDeletionWarning(er)"
                        v-bind="attrs"
                        v-on="on"
                        color="primary"
                        >mdi-delete
                      </v-icon>
                    </template>
                    <span>Registrierungseinladung löschen</span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-row class="ma-0 pa-0">
                <v-col cols="3" class="ma-0 pa-0">
                  <p class="ma-0 pa-0">Email</p>
                </v-col>
                <v-col cols="9" class="ma-0 pa-0">
                  <p class="ma-0 pa-0">
                    <b>{{ er.email }} </b>
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else  style="flex-grow: 0">
        <v-col class="pl-0">
          <i>Keine offenen Einladungen</i>
        </v-col>
      </v-row>

      <v-row  style="flex-grow: 0">
        <v-col class="pb-0 pl-0 mt-2">
          <h2 class="encodeFont">Registrierte Redakteure</h2>
        </v-col>
      </v-row>
      <v-row v-if="editors.length > 0"  style="flex-grow: 0">
        <v-col
          v-for="(e, index) in editors"
          :key="e.id"
          :class="{
            'pr-2': index % 2 == 0 && !isMobile ? true : false,
            'pl-2': index % 2 != 0 && !isMobile ? true : false,
            'pr-0': index % 2 != 0 && !isMobile ? true : false,
            'pl-0': index % 2 == 0 && !isMobile ? true : false,
            'px-0': isMobile,
          }"
          :cols="isMobile ? 12 : 6"
        >
          <v-card flat class="pa-2">
            <v-container fluid>
              <v-row class="ma-0 pa-0">
                <v-col class="ma-0 pa-0 pb-2" cols="10">
                  <h2 class="encodeFont">
                    {{
                      getReadableSalutation(e.salutation) +
                      " " +
                      e.first_name +
                      " " +
                      e.last_name
                    }}
                    ({{ e.permission == 1 ? "Admin" : "Redakteur" }})
                  </h2>
                </v-col>
                <v-spacer />
                <v-col cols="auto" class="ma-0 pa-0 pb-2">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        @click="openEditView(e)"
                        v-bind="attrs"
                        v-on="on"
                        color="primary"
                        >mdi-pencil
                      </v-icon>
                    </template>
                    <span>Redakteur editieren</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        @click="showDeletionWarning(e)"
                        :disabled="e.permission == 1"
                        v-bind="attrs"
                        v-on="on"
                        color="primary"
                        >mdi-delete
                      </v-icon>
                    </template>
                    <span>Redakteur Löschen</span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-row class="ma-0 pa-0">
                <v-col cols="3" class="ma-0 pa-0">
                  <p class="ma-0 pa-0">Email</p>
                </v-col>
                <v-col cols="9" class="ma-0 pa-0">
                  <p class="ma-0 pa-0">
                    <b>{{ e.email }} </b>
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else  style="flex-grow: 0">
        <v-col class="pl-0">
          <i>Keine registrierten Redakteure</i>
        </v-col>
      </v-row>
    </v-container>

    <v-overlay :value="b_showEditorEdit" :dark="false" style="z-index: 12 !important">
      <editorEdit
        :editorData="activeEditor"
        @close="hideEditorEdit"
        @error="showErrorOverlay"
      />
    </v-overlay>

    <v-overlay :value="b_showErrorOverlay" :dark="false" style="z-index: 12 !important">
      <v-card class="pa-6">
        <v-container>
          <v-row>
            <v-col>
              <h2 class="encodeFont">Ein Fehler ist aufgetreten</h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <p>{{ errorMessage }}</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                @click="hideErrorOverlay()"
                large
                block
                class="py-3 primaryButton"
                elevation="0"
                >OK</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-overlay>

    <v-overlay :value="b_showResendWarning" :dark="false">
      <resendWarning
        v-if="activeEditor"
        :editorRequest="activeEditor"
        @close="hideResendWarning"
        @confirm="resendInvite"
      />
    </v-overlay>

    <v-overlay :value="b_showDeletionWarning" :dark="false">
      <deletionWarning
        v-if="activeEditor"
        :data="activeEditor.first_name + ' ' + activeEditor.last_name"
        :item="activeEditor.permission ? ' der Redakteur' : ' die Einladung für'"
        @cancel="hideDeletionWarning"
        @confirm="confirmDeletion"
      />
    </v-overlay>

    <v-overlay :value="loading">
      <v-progress-circular indeterminate color="accent"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import editorEdit from "../../components/Backoffice/Editor/EditorEdit.vue";
import deletionWarning from "../../components/Main/DeletionWarning.vue";
import resendWarning from "../../components/Backoffice/Editor/ResendInviteWarning.vue";

const axios = require("axios");

export default {
  name: "adminPanel",

  data() {
    return {
      b_showEditorEdit: false,
      activeEditor: undefined,

      b_showErrorOverlay: false,
      errorMessage: undefined,

      b_showDeletionWarning: false,
      b_showResendWarning: false,

      editorRequests: [],
      editors: [],

      loading: false,
    };
  },

  components: {
    editorEdit,
    deletionWarning,
    resendWarning,
  },

  computed: {
   getContainerHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "unset";
        case "sm":
          return "unset";
        case "md":
          return "70vh";
        case "lg":
          return "80vh";
        case "xl":
          return "80vh";
        default:
          return "80vh";
      }
    },
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return true;
        case "lg":
          return false;
        case "xl":
          return false;
        default:
          return false;
      }
    },
  },

  methods: {
    hideEditorEdit(data) {
      this.activeEditor = undefined;
      this.b_showEditorEdit = false;

      if (data) {
        this.getEditorData();
        this.getEditorRequestData();
      }
    },
    openEditView(e) {
      this.activeEditor = e;
      this.b_showEditorEdit = true;
    },
    getEditorRequestData() {
      axios
        .get(axios.defaults.baseURL + "editorrequests/")
        .then((resp) => {
          this.editorRequests = resp.data;
          this.$forceUpdate();
        })
        .catch((e) => {
          console.log(e);
          window.dispatchEvent(new CustomEvent("showErrorDisplay"));
        });
    },
    getEditorData() {
      axios
        .get(axios.defaults.baseURL + "editors/")
        .then((resp) => {
          this.editors = resp.data;

          this.$forceUpdate();
        })
        .catch((e) => {
          console.log(e);
          window.dispatchEvent(new CustomEvent("showErrorDisplay"));
        });
    },
    getReadableSalutation(value) {
      switch (value) {
        case 2:
          return "Frau";
        case 3:
          return "Herr";
        default:
          return "";
      }
    },
    showErrorOverlay(data) {
      this.errorMessage = data;
      this.b_showErrorOverlay = true;

      this.b_showEditorEdit = false;
    },
    hideErrorOverlay() {
      this.errorMessage = undefined;
      this.b_showErrorOverlay = false;
    },
    showDeletionWarning(data) {
      this.b_showDeletionWarning = true;
      this.activeEditor = data;
    },
    hideDeletionWarning() {
      this.b_showDeletionWarning = false;
      this.activeEditor = undefined;
    },
    confirmDeletion() {
      if (this.activeEditor.permission) {
        axios
          .delete(axios.defaults.baseURL + "editors/delete/" + this.activeEditor.id)
          .then(() => {
            this.getEditorData();

            this.b_showDeletionWarning = false;
            this.activeEditor = undefined;
          })
          .catch((e) => {
            console.log(e);
            this.activeEditor = undefined;
            this.b_showErrorOverlay = true;
            this.errorMessage =
              "Der Redakteur konnte nicht gelöscht werden. Prüfen Sie Ihre Berechtigungen oder versuchen Sie es erneut.";
          });
      } else {
        axios
          .delete(
            axios.defaults.baseURL + "editorrequests/delete/" + this.activeEditor.id
          )
          .then(() => {
            this.getEditorRequestData();
            this.activeEditor = undefined;
            this.b_showDeletionWarning = false;
          })
          .catch((e) => {
            console.log(e);
            this.b_showErrorOverlay = true;
            this.activeEditor = undefined;
            this.errorMessage =
              "Die Einladung konnte nicht gelöscht werden. Prüfen Sie Ihre Berechtigungen oder versuchen Sie es erneut.";
          });
      }
    },
    showResendWarning(data) {
      this.activeEditor = data;
      this.b_showResendWarning = true;
    },
    hideResendWarning() {
      this.activeEditor = undefined;
      this.b_showResendWarning = false;
    },
    resendInvite(data) {
      this.loading = true;

      axios
        .patch(axios.defaults.baseURL + "editorrequests/patch/" + data.id)
        .then(() => {
          this.b_showResendWarning = false;
          this.activeEditor = undefined;

          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.b_showResendWarning = false;

          this.errorMessage =
            "Die Einladung konnte nicht versendet werden. (Einladungen können nur alle 24h neu versendet werden)";
          this.b_showErrorOverlay = true;

          this.loading = false;
        });
    },
  },

  mounted() {
    this.getEditorData();
    this.getEditorRequestData();
  },
};
</script>
