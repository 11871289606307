<template>
  <div>
    <div style="min-height: 100vh">
      <headerComponent v-if="!isMobile" />

      <div
        id="test"
        style="display: flex; margin: 0 auto"
        :style="{ width: getContainerWidth }"
      >
        <v-container fluid class="pa-0 ma-0">
          <v-row class="pa-0 ma-0" v-if="isMobile">
            <v-col class="pa-0 ma-0">
              <v-expansion-panels accordion v-model="searchPanel" style="z-index: 3">
                <v-expansion-panel>
                  <v-expansion-panel-header class="darkAccordion">
                    <div @click="mainImgClick()" tag="button" class="headerLink">
                      <v-img
                        id="headerImg"
                        src="../../assets/images/logo.svg"
                        class="headerLink"
                        style="cursor: pointer; height: 58px; width: 58px"
                      ></v-img>
                    </div>
                    <span>Suche</span></v-expansion-panel-header
                  >
                  <v-expansion-panel-content class="darkAccordion">
                    <searchComponent
                      @search="search"
                      :singleLine="false"
                      @showMobileFilter="mobileFilterDrawer++"
                      :searchAgain="searchAgainBiggerPerimeter"
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
          <br v-else />
          <v-row class="pa-0 ma-0">
            <v-col class="pa-0 ma-0">
              <v-card
                style="width: fit-content; position: absolute; opacity: 0.9; z-index: 2"
                :style="{ top: mapHeight }"
                class="px-1"
                flat
              >
                <i>{{ resultCount }} Ergebnisse gefunden</i>
              </v-card>

              <ClusterMap
                :style="{ width: getContainerWidth, height: mapHeight }"
                v-if="cookieAccepted && !showMobileJDDetail"
                :smallMap="smallMap"
                :showFavorites="showFavorites"
                :selectedJD="selectedJD"
                :selectedEvent="selectedEvent"
                :refreshDisplay="refreshMapDisplay"
                @toggleFavorites="fetchFavorites"
                @fetchJobs="fetchByIds"
                @toggleSize="smallMap = !smallMap"
                @eventMarkerClicked="eventClicked"
              />

              <v-card
                v-else
                :style="{ width: getContainerWidth, height: mapHeight }"
                style="margin: 0 auto; background-color: #171717"
                flat
                class="rounded-xs"
              >
                <v-container fill-height>
                  <v-row>
                    <v-spacer />
                    <v-col cols="auto">
                      <v-card flat style="background-color: #171717" class="hideShadow">
                        <v-card-title style="font-size: larger; color: white">
                          Cookieeinstellungen</v-card-title
                        >
                        <v-card-text style="font-size: large; color: white">
                          <a @click="gotoImpressum">Impressum</a> |
                          <a @click="gotoDatenschutz">Datenschutz</a>
                          <br />
                          <br />
                          Zum Anzeigen dieser Karte werden externe Inhalte (Mapbox)
                          benötigt. Hierfür müssen Sie die entsprechenden Cookies
                          zulassen.<br />
                          <br />
                          <button text @click="openConsentManager()">
                            <u style="color: var(--v-accent-base)"
                              >Einstellungen verwalten</u
                            >
                          </button>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-spacer />
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-if="isMobile" class="pa-0 ma-0 pt-2 pl-2">
            <v-col class="pa-0 ma-0">
              <v-card v-if="!isMobile" style="width: fit-content" class="px-1" flat>
                <i>{{ resultCount }} Ergebnisse gefunden</i>
              </v-card>
            </v-col>
          </v-row>

          <v-row class="pa-0 ma-0" v-if="!isMobile">
            <v-col class="pa-0 ma-0 pb-1">
              <searchComponent
                @toggleFavorites="fetchFavorites"
                @search="search"
                :singleLine="true"
                :searchAgain="searchAgainBiggerPerimeter"
              />
            </v-col>
          </v-row>
          <v-row class="pa-0 ma-0" :class="{ 'mt-2': isMobile, 'mb-6': isMobile }">
            <v-col
              :cols="isMobile ? 12 : 5"
              class="py-0 pl-0 pr-2"
              :class="{
                'pl-2': isMobile,
                scrollable: !isMobile,
              }"
              :style="{ height: !isMobile ? resultHeight : 'unset' }"
              id="resultRow"
            >
              <v-container fluid class="py-0 my-0">
                <v-row>
                  <v-col
                    cols="12"
                    class="pa-0 ma-0 pb-4 mt-4"
                    v-if="searchResults.length == 0"
                  >
                    <i>Keine Ergebnisse gefunden... </i>
                  </v-col>

                  <v-col
                    cols="12"
                    v-for="(result, index) in jdAndEventList"
                    :key="result.data.id"
                    class="pa-0 ma-0 pb-4"
                  >
                    <resultCardComponent
                      v-if="result.type == 'jd'"
                      :id="'resultCard_' + result.data.id"
                      :style="{ 'margin-top': index == 0 ? '12px' : '0px' }"
                      :class="{
                        primaryOutline:
                          selectedJD == searchResults[result.index] ? true : false,
                      }"
                      @click="showDetailView"
                      :result="result.data"
                      :index="result.index"
                      :showPublished="false"
                      :favorites="favorites"
                      @switchFocus="switchFocus"
                      @addFavorite="addToFavorites"
                      @removeFavorite="removeFromFavorites"
                    />
                    <eventCarouselComponent
                      v-else-if="result.type == 'event'"
                      :style="{ 'margin-top': index == 0 ? '12px' : '0px' }"
                      :class="{
                        primaryOutline:
                          selectedJD == searchResults[result.index] ? true : false,
                      }"
                      @select="showEventDetailView"
                      :events="events"
                      @switchFocus="switchEventFocus"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    class="pa-0 ma-0 pb-4"
                    style="display: flex; justify-content: center"
                  >
                    <v-btn
                      class="primaryButton py-3"
                      style="width: 50%"
                      @click="page++"
                      id="loadMoreButton"
                      :disabled="page * 20 > resultCount"
                    >
                      Mehr laden
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col cols="7" class="pa-0 ma-0 pl-2" v-if="!isMobile">
              <resultDetail
                v-if="selectedJD"
                :jobDescriptionData="selectedJD"
                :height="resultHeight"
                :favorites="favorites"
                @resetFocus="resetFocus"
                @addFavorite="addToFavorites"
                @removeFavorite="removeFromFavorites"
              />
              <eventDetail
                v-if="selectedEvent"
                :eventData="selectedEvent"
                :height="resultHeight"
                :resultView="true"
                @resetFocus="resetEventFocus"
              />
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>

    <mobileSearchFilter
      @search="gotoSearch"
      :open="mobileFilterDrawer"
      @closeDrawer="mobileFilterDrawer = 0"
    />

    <resultDetailMobile
      :jobDescriptionData="selectedJD"
      :height="'100vh'"
      :open="showMobileJDDetail"
      @closeDrawer="showMobileJDDetail = 0"
      @addFavorite="addToFavorites"
      @removeFavorite="removeFromFavorites"
      :favorites="favorites"
    />

    <eventDetailMobile
      :eventData="selectedEvent"
      :resultView="true"
      :height="'100vh'"
      :open="showMobileEventDetail"
      @close="showMobileEventDetail = 0"
    />

    <footerComponent class="mt-16" />

    <v-snackbar v-model="resultsSnackbar" :multi-line="true" :timeout="-1" top>
      Die Suche verlief mit 0 Suchergebnissen. Der Umkreis der Suche wurde automatisch auf
      {{ this.$SearchFilterHandler.searchFilters.perimeter }} erhöht.
      <template v-slot:action="{ attrs }">
        <v-btn class="py-3" text v-bind="attrs" @click="resultsSnackbar = false">
          Schließen
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import headerComponent from "../../components/Main/Header.vue";
import footerComponent from "../../components/Main/Footer.vue";
import searchComponent from "./Search.vue";
import resultDetail from "../../components/JobDescriptions/ResultDetail.vue";
// import mapComponent from "./Map.vue";
import resultCardComponent from "../../components/JobDescriptions/ResultCard.vue";
import resultDetailMobile from "../../components/JobDescriptions/ResultDetail_MobileDrawer.vue";
import mobileSearchFilter from "../../components/Search/SearchFilter_MobileDrawer.vue";
import eventCarouselComponent from "../../components/Backoffice/Event/EventCarousel.vue";
import eventDetail from "../../components/Backoffice/Event/EventDetail.vue";
import eventDetailMobile from "../../components/Backoffice/Event/EventDetail_MobileDrawer.vue";
import ClusterMap from "./ClusterMap.vue";

// import goTo from 'vuetify/lib/services/goto'

const axios = require("axios");

export default {
  name: "searchResults",

  metaInfo: {
    title:
      "Ausbildungs- und Praktikumsstellen der digitalen Suchplattform der IHK Halle-Dessau.",
    titleTemplate: "%s - Ergebnisse",
    htmlAttrs: {
      lang: "de",
    },
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content: "Suchergebnisse für Ausbildungs- und Praktikumsstellen",
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
    ],
  },

  data() {
    return {
      searchQ: "",
      searchResults: [],
      events: [],

      jdAndEventList: [],

      mapUpdateCounter: 0,
      refreshMapDisplay: 0,

      selectedJD: undefined,
      showMobileJDDetail: 0,

      selectedEvent: undefined,
      showMobileEventDetail: 0,

      searchPanel: [],

      mobileFilterDrawer: 0,
      page: 1,
      resultCount: 0,

      mapFocus: undefined,

      cookieAccepted: false,

      selectedCity: undefined,

      smallMap: !this.isMobile,

      favorites: undefined,
      showFavorites: false,

      searchAgainBiggerPerimeter: 0,
      resultsSnackbar: false,
    };
  },

  computed: {
    getContainerWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "md":
          return "100vw";
        case "lg":
          return "88vw";
        case "xl":
          return "75vw";
        default:
          return "100vw";
      }
    },
    mapHeight() {
      if (this.smallMap) {
        if (window.innerHeight < 750) {
          return "30vh";
        }
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return "55vh";
          case "sm":
            return "50vh";
          case "md":
            return "45vh";
          case "lg":
            return "30vh";
          case "xl":
            return "30vh";
          default:
            return "30vh";
        }
      } else {
        if (window.innerHeight < 750) {
          return "60vh";
        }
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return "75vh";
          case "sm":
            return "70vh";
          case "md":
            return "70vh";
          case "lg":
            return "60vh";
          case "xl":
            return "60vh";
          default:
            return "60vh";
        }
      }
    },
    resultHeight() {
      if (window.innerHeight < 750) {
        return "calc(70vh - 190px)";
      }
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "35vh";
        case "sm":
          return "45vh";
        case "md":
          return "calc(55vh - 190px)";
        case "lg":
          return "calc(70vh - 190px)";
        case "xl":
          return "calc(70vh - 190px)";
        default:
          return "30vh";
      }
    },
    isMobile() {
      if (window.innerHeight < 750) {
        return true;
      }
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return false;
        default:
          return false;
      }
    },
  },

  components: {
    headerComponent,
    footerComponent,
    searchComponent,
    resultDetail,
    // mapComponent,
    resultCardComponent,
    resultDetailMobile,
    mobileSearchFilter,
    eventCarouselComponent,
    eventDetail,
    eventDetailMobile,
    ClusterMap,
  },

  watch: {
    page() {
      if (this.page != 1) this.requestDB();
    },
    selectedEvent() {
      if (this.selectedEvent) {
        this.selectedJD = undefined;
        this.showMobileJDDetail = 0;
      }
    },
    selectedJD() {
      if (this.selectedJD) {
        this.selectedEvent = undefined;
        this.showMobileEventDetail = 0;
      }
    },
  },

  methods: {
    mainImgClick() {
      this.$router.push("/");
    },
    openConsentManager() {
      window.dispatchEvent(new CustomEvent("openConsentManager"));
    },
    switchFocus() {
      document.getElementById("resultDetail").focus();
    },
    switchEventFocus() {
      document.getElementById("eventDetail").focus();
    },
    resetFocus() {
      for (let i = 0; i < this.searchResults.length; i++) {
        if (this.searchResults[i].id == this.selectedJD.id) {
          if (i < this.searchResults.length - 1) {
            document.getElementById("resultCard_" + this.searchResults[i + 1].id).focus();
          } else {
            document.getElementById("loadMoreButton").focus();
          }

          return;
        }
      }
    },
    resetEventFocus() {
      document
        .getElementById("eventCard_" + this.selectedEvent.id)
        .parentNode.parentNode.parentNode.focus();
    },
    markerClicked(data) {
      for (let i = 0; i < this.searchResults.length; i++) {
        if (this.searchResults[i].id == data) {
          this.selectedJD = this.searchResults[i];

          let resultCard = document.getElementById(
            "resultCard_" + this.searchResults[i].id
          );

          if (this.isMobile) {
            resultCard.scrollIntoView({ block: "start", behavior: "smooth" });
          } else {
            let resultRow = document.getElementById("resultRow");

            let topPos = resultCard.offsetTop;

            resultRow.scrollTop = topPos - resultRow.offsetTop;
          }

          break;
        }
      }
    },
    eventClicked(data) {
      for (let i = 0; i < this.events.length; i++) {
        if (this.events[i].id == data) {
          this.selectedEvent = this.events[i];

          this.$forceUpdate();
          break;
        }
      }
    },
    search(retry = false) {
      this.searchQ = this.$SearchFilterHandler.getSearchQ();

      this.showFavorites = false;

      this.page = 1;

      setTimeout(
        () => {
          this.requestDB(true, retry);
        },
        retry ? 500 : 0
      );

      this.searchPanel = [];
    },
    gotoSearch() {
      this.mobileFilterDrawer = 0;
      this.searchQ = this.$SearchFilterHandler.getSearchQ();
      this.$router.push("/suche/" + this.searchQ);
    },
    requestDB(reload = false, retry = false) {
      this.searchQ = this.$SearchFilterHandler.getSearchQ();

      axios
        .get(
          axios.defaults.baseURL +
            "search/" +
            (!this.showFavorites ? this.searchQ : "?search_multi_match=") +
            "&page=" +
            this.page +
            (this.showFavorites ? "&job_ids=" + (localStorage.getItem("favoriteJDs") ? localStorage.getItem("favoriteJDs") : '0') : "")
        )
        .then((resp) => {
          if (reload) {
            this.jdAndEventList = [];

            this.mapUpdateCounter++;

            this.searchResults = resp.data.results;
            this.resultCount = resp.data.count;

            this.selectedJD = this.searchResults[0];

            for (let i = 0; i < this.searchResults.length; i++) {
              this.jdAndEventList.push({
                data: this.searchResults[i],
                type: "jd",
                index: i,
              });
            }

            this.refreshMapDisplay++;

            this.requestEvent();

            if (
              this.resultCount == 0 &&
              !retry &&
              !this.showFavorites &&
              this.$SearchFilterHandler.searchFilters.selectedCity
            ) {
              this.searchAgainBiggerPerimeter++;
            } else if (retry == true) {
              this.resultsSnackbar = true;
            }
          } else {
            document
              .getElementById(
                "resultCard_" + this.searchResults[this.searchResults.length - 1].id
              )
              .focus();

            this.searchResults = this.searchResults.concat(resp.data.results);

            for (let i = 0; i < resp.data.results.length; i++) {
              this.jdAndEventList.push({
                data: resp.data.results[i],
                type: "jd",
                index: this.searchResults.length - resp.data.results.length + i,
              });
            }

            this.$forceUpdate();
          }

          // setTimeout(() => {
          //   if (this.searchResults.length > 0) {
          //     let resultCard = document.getElementById(
          //       "resultCard_" + this.searchResults[0].id
          //     );
          //     let resultRow = document.getElementById("resultRow");

          //     let topPos = resultCard.offsetTop;

          //     resultRow.scrollTop = topPos - resultRow.offsetTop;
          //   }
          // }, 5000);
        })
        .catch((e) => {
          console.log(e);
          window.dispatchEvent(new CustomEvent("showErrorDisplay"));
        });

      if (this.$SearchFilterHandler.searchFilters.selectedCity) {
        this.selectedCity = this.$SearchFilterHandler.searchFilters.selectedCity;
      }
    },
    showDetailView(index) {
      this.selectedJD = this.searchResults[index];
      // console.log(this.searchResults[index]);

      this.mapFocus = this.selectedJD;

      if (this.isMobile) {
        this.showMobileJDDetail++;
      }
    },
    showEventDetailView(eventID) {
      for (let i = 0; i < this.events.length; i++) {
        if (this.events[i].id == eventID) {
          this.selectedEvent = this.events[i];

          if (this.isMobile) {
            this.showMobileEventDetail++;
          }

          break;
        }
      }
    },
    requestEvent() {
      axios
        .get(axios.defaults.baseURL + "events")
        .then((resp) => {
          this.events = resp.data;

          if (this.jdAndEventList.length >= 4) {
            this.jdAndEventList.splice(3, 0, {
              type: "event",
              index: 4,
              data: this.events,
            });
          } else {
            this.jdAndEventList.push({
              type: "event",
              index: 4,
              data: this.events,
            });
          }

          ++this.mapUpdateCounter;
        })
        .catch((e) => {
          console.log(e);
          window.dispatchEvent(new CustomEvent("showErrorDisplay"));
        });
    },
    gotoImpressum() {
      this.$router.replace("/impressum");
    },
    gotoDatenschutz() {
      this.$router.replace("/datenschutz");
    },
    getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    fetchFavorites(showFavorites) {
      this.showFavorites = showFavorites;
      this.requestDB(true);
    },
    fetchByIds(job_ids) {
      this.showFavorites = false;
      axios
        .post(axios.defaults.baseURL + "jobdescriptions/by/ids/", {
          job_ids: job_ids,
        })
        .then((resp) => {
          this.searchResults = resp.data;
          this.resultCount = resp.data.length;
          this.jdAndEventList = [];

          for (let i = 0; i < this.searchResults.length; i++) {
            this.jdAndEventList.push({
              data: this.searchResults[i],
              type: "jd",
              index: i,
            });
          }

          this.requestEvent();
          this.selectedJD = this.searchResults[0];

          this.mapUpdateCounter++;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    addToFavorites(id) {
      if (localStorage.getItem("favoriteJDs") != null) {
        let favorites = localStorage.getItem("favoriteJDs").split(",");
        favorites.push(id.toString());
        localStorage.setItem("favoriteJDs", favorites);
        this.favorites = favorites;

        if (this.showFavorites) {
          this.refreshMapDisplay++;
        }
      } else {
        localStorage.setItem("favoriteJDs", id);
        this.favorites = [id.toString()];

        if (this.showFavorites) {
          this.refreshMapDisplay++;
        }
      }
    },
    removeFromFavorites(id) {
      if (localStorage.getItem("favoriteJDs") != null) {
        let favorites = localStorage.getItem("favoriteJDs").split(",");
        favorites.splice(favorites.indexOf(id.toString()), 1);
        if (this.showFavorites) {
          this.selectedJD = this.searchResults[0];
          this.resultCount--;
          for (let i = 0; i < this.jdAndEventList.length; i++) {
            if (this.jdAndEventList[i].data.id == id) {
              this.jdAndEventList.splice(i, 1);
              break;
            }
          }
        }
        if (favorites.length > 0) {
          localStorage.setItem("favoriteJDs", favorites);
          this.favorites = favorites;
        } else {
          localStorage.removeItem("favoriteJDs");
          this.favorites = undefined;
        }
      }
      if(this.showFavorites)
        this.refreshMapDisplay++;
    },
  },

  mounted() {
    const url = window.location.href.split("/")[
      window.location.href.split("/").length - 1
    ];
    // this.$SearchFilterHandler.setSearchQ(url);
    const test = new URLSearchParams(atob(url));

    this.searchQ = this.$SearchFilterHandler.getSearchQ();
    if (test.get("job_ids")) {
      this.fetchByIds(test.get("job_ids").split(","));
    } else {
      this.requestDB(true);
    }

    let consentCookie = this.getCookie("cookieConsent");

    if (consentCookie == "") {
      this.cookieAccepted = false;
    } else if (!consentCookie || consentCookie == "false") {
      this.cookieAccepted = false;
    } else {
      this.cookieAccepted = true;
    }
    if (localStorage.getItem("favoriteJDs") != null) {
      this.favorites = localStorage.getItem("favoriteJDs").split(",");
    }
  },
};
</script>
