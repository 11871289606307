<template>
  <div style="height: 100%; display: flex; flex-direction: column">
    <div class="py-1 chartHeader" style="flex-grow: 0;">
      <h2 class="encodeFont pl-2" style="color: white !important">
        {{ label }}
      </h2>
    </div>
    <v-container v-if="cities" fluid class="px-8 pt-4 pb-4" style="flex-grow: 1">
      <v-row justify="center" align="center">
        <v-col
          :cols="mainCols"
          class="pa-2"
          v-for="(city, index) in cities"
          :key="index"
        >
          <CityKPIVue :city="city" />
        </v-col>
      </v-row>
    </v-container>
    <div
        v-else
        style="height: 500px;display: flex; flex-grow: 1; justify-content: center; align-items: center"
      >
        <v-progress-circular indeterminate color="accent"></v-progress-circular>
      </div>
  </div>
</template>

<script>
import CityKPIVue from "./CityKPI.vue";

export default {
  name: "CitiesChart",
  props: ["cities", "label"],

  components: {
    CityKPIVue,
  },
  computed: {
    mainCols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 6;
        case "sm":
          return 6;
        case "md":
          return 4;
        default:
          return 3;
      }
    },
  },
};
</script>
