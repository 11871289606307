<template>
  <div id="companySetup" style="width: 100%" class="mb-16">
    <v-container v-if="companyData" :style="{ 'margin-top': isMobile ? '32px' : '0px' }">
      <v-row>
        <v-col style="display: flex; align-items: flex-end" v-if="!isMobile">
          <v-btn
            class="primaryButton py-3"
            style="height: unset !important"
            @click="changeStep(-1)"
            :disabled="step == 1"
          >
            <v-icon> mdi-arrow-left </v-icon>
          </v-btn>
        </v-col>
        <v-col :cols="isMobile ? 12 : 8">
          <v-stepper non-linear flat v-model="step">
            <v-stepper-header>
              <v-stepper-step :complete="step > 1" step="1">
                Kontaktperson
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step :complete="step > 2" step="2">
                Filialeninformation
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step :complete="step > 3" step="3">
                Stellenanzeigen importieren
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <contactEdit
                  v-if="companyData"
                  @close="updateCompletedContact"
                  @error="loading = false"
                  :updateNow="postUpdate_editContact"
                  :contactData="
                    contactData ? contactData
                      : undefined
                  "
                  :companyId="companyData.id"
                  :cardHeight="isMobile ? 'auto' : 'fit-content'"
                  :setupView="true"
                />
              </v-stepper-content>
              <v-stepper-content step="2">
                <companyEdit
                  v-if="companyData"
                  @close="updateCompletedCompany"
                  @error="loading = false"
                  :updateNow="postUpdate_editCompany"
                  :companyData="companyData"
                  :setupView="true"
                />
              </v-stepper-content>
              <v-stepper-content step="3">
                <jdSetupList
                  v-if="companyData"
                  @finish="updateCompletedJD"
                  @error="loading = false"
                  @startLoad="loading = true"
                  :updateNow="postUpdate_editJD"
                  :jobDescriptions="companyData ? companyData.jd : []"
                  :cardHeight="isMobile ? 'auto' : 'fit-content'"
                  :setupView="true"
                />
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-col>
        <v-col
          v-if="isMobile"
          style="display: flex; align-items: flex-end; justify-content: center"
        >
          <v-btn
            class="primaryButton py-3"
            @click="changeStep(-1)"
            style="height: unset !important"
            :disabled="step == 1"
          >
            <v-icon> mdi-arrow-left </v-icon>
          </v-btn>
        </v-col>
        <v-col style="display: flex; align-items: flex-end; justify-content: center">
          <v-btn
            class="primaryButton py-3"
            @click="changeStep(1)"
            style="height: unset !important"
            :disabled="step == 3"
          >
            <v-icon> mdi-arrow-right </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-overlay :value="loading">
      <v-progress-circular indeterminate color="accent"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import companyEdit from "../../components/Backoffice/Company/CompanyEdit.vue";
import contactEdit from "../../components/Backoffice/Company/ContactEdit.vue";
import jdSetupList from "../../components/Backoffice/JobDescription/JDList.vue";

const axios = require("axios");

export default {
  name: "companySetup",

  data() {
    return {
      step: 1,

      companyData: undefined,
      contactData: undefined,

      postUpdate_editCompany: 0,
      postUpdate_editContact: 0,
      postUpdate_editJD: 0,

      loading: false,
    };
  },

  components: {
    companyEdit,
    contactEdit,
    jdSetupList,
  },

  computed: {
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        default:
          return false;
      }
    },
  },

  methods: {
    changeStep(val) {
      if (val < 0) {
        this.step--;

        if (this.step < 1) this.step = 1;

        return;
      }

      switch (this.step) {
        case 1:
          this.updateContacts();
          break;
        case 2:
          this.updateCompany();
          break;
      }
    },

    updateCompany() {
      this.loading = true;
      this.postUpdate_editCompany++;
    },
    updateContacts() {
      this.loading = true;
      
      this.postUpdate_editContact++;

    },

    updateCompletedCompany() {
      this.loading = false;
      this.step++;
    },
    updateCompletedContact() {
      this.loading = false;

      this.fetchCompanies().then(() => {
        this.step++;

      })
    },
    updateCompletedJD() {
      axios
        .post(axios.defaults.baseURL + "user/patch/", {
          setup_complete: true,
        })
        .then(() => {
          this.loading = false;
          this.$router.push("/unternehmen/unternehmensprofil");
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
          window.dispatchEvent(new CustomEvent("showErrorDisplay"));
        });
    },
    fetchContacts(){
      axios.get(axios.defaults.baseURL + "contactpersons/own").then((resp) => {
          if (resp.data && resp.data[0]) {
            this.contactData = resp.data[0];
          }

          this.$forceUpdate();
        });
    },
    fetchCompanies(){
      return new Promise((resolve) => {

        axios.get(axios.defaults.baseURL + "companies/own").then((resp) => {
          this.companyData = resp.data[0];
  
          this.fetchContacts()

          resolve()
        });
      })
    }
    
  },

  mounted() {
    if (
      this.$store.state.setupComplete == "false" ||
      this.$store.state.setupComplete == false
    ) {
      this.fetchCompanies()
    }
  },
};
</script>
