<template>
  <div style="height: 100%; display: flex; flex-direction: column">
    <div class="py-1 chartHeader" style="flex-grow: 0">
      <h2 class="encodeFont pl-2" style="color: white !important">
        {{ label }}
      </h2>
    </div>
    <v-container
      v-if="searches"
      fluid
      style="display:flex; align-items: center; flex-grow: 1"
    >
      <v-row
        style="align-content:center; height: 100%; justify-content: center"
      >
        <v-col
          cols="10"
          style="display:flex; justify-content: center; flex-grow: 1; height: 25%"
        >
          <h1
            class="encodeFont"
            style="color: var(--v-primary-base) !important; font-size: xxx-large"
          >
            {{ searches["searches_total"] }}
          </h1>
        </v-col>

        <v-col
          cols="12"
          style="flex-grow: 1; height: 25%; justify-content: center"
        >
          <div style="background-color: #B9B7B7; margin: 0 auto; width: 67%">
            <h1 class="encodeFont text-center" style="color: white !important">
              +{{ searches["searches_today"] }} von heute
            </h1>
          </div>
        </v-col>

        <v-spacer />
      </v-row>
    </v-container>
    <div
      v-else
      style="display: flex; flex-grow: 1; justify-content: center; align-items: center"
    >
      <v-progress-circular indeterminate color="accent"></v-progress-circular>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchesCount",
  props: ["searches", "label"],

  computed: {
    fontSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "4em";
        case "sm":
          return "4em";
        case "md":
          return "6em";
        case "lg":
          return "6em";
        case "xl":
          return "8em";
        default:
          return "6em";
      }
    },
  },
};
</script>
