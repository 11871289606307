<template>
  <div id="ownJD" style="width: 100%">
    <v-container
      fluid
      style="margin: 0 auto
    display: flex;
    flex-direction: column;"
    >
      <v-row style="flex-grow: 0">
        <v-col :cols="12" class="pa-0 pr-1">
          <v-container fluid class="pa-0">
            <v-row class="ma-0">
              <v-col :cols="6" class="pa-0 pr-1">
                <v-card flat>
                  <v-card-title class="encodeFont pa-0 pb-1"
                    ><v-text-field
                      label="Firmenname eingeben"
                      clearable
                      class="hideInputMessage px-4"
                      background-color="white"
                      v-model="searchString"
                      append-icon="mdi-magnify"
                    ></v-text-field
                  ></v-card-title>
                </v-card>
              </v-col>
              <v-spacer />
              <v-col cols="auto" class="pa-0 pl-3">
                <v-btn
                  @click="openEditView()"
                  large
                  elevation="0"
                  block
                  class="primaryOnWhiteButton pa-0"
                  style="width: 64px"
                  ><v-icon> mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <v-row class="my-0">
              <v-col cols="auto" class="py-0 pl-7">
                <small>{{ totalJDs }} Treffer</small>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>

      <v-row style="flex-grow: 1">
        <v-col
          :cols="isMobile ? 12 : 5"
          class="px-0"
          style="
            display: flex;
            flex-direction: column;
            padding-top: 0px;
            padding-bottom: 2px;
            margin-top: 12px;
          "
          :style="{ height: !isMobile ? 'calc(-201px + 100vh )' : 'unset' }"
        >
          <div
            class="scrollable hiddenScroll"
            :class="{ disableOverscroll: isMobile }"
            id="scrollBox"
          >
            <div
              style="height: fit-content"
              v-for="(j, index) in jobDescriptions"
              :key="j.id"
              flat
              @click="showDetailView(j)"
              class="mb-3"
            >
              <resultCard
                :showPublished="true"
                :result="j"
                @switchFocus="switchFocus"
                @selectCard="showDetailView(j)"
              />

              <div
                v-if="index == jobDescriptions.length - 1"
                tabindex="0"
                @focus="rerouteFocus"
                style="opacity: 0; height: 0px"
              >
                Tastatursteuerung Hilfsanker Start
              </div>
            </div>
            <div v-if="jobDescriptions.length == 0">
              <i
                >Keine Stellenangebote
                <span v-if="searchString">
                  für <b> {{ searchString }}</b></span
                >
                vorhanden</i
              >
            </div>
          </div>

          <v-pagination
            id="paginator"
            class="mt-2 focusableNoBorder"
            tabindex="0"
            v-model="page"
            :length="maxPages"
            :total-visible="7"
          ></v-pagination>
        </v-col>
        <v-col
          cols="7"
          v-if="!isMobile"
          class="pb-0 pr-0"
          :style="{ height: !isMobile ? 'calc(-201px + 100vh )' : 'unset' }"
        >
          <resultDetail
            id="resultDetail"
            :jobDescriptionData="activeJD"
            :height="getContainerHeight"
            :isEditView="true"
            :editorView="true"
            @edit="openEditView(activeJD)"
            @delete="showDeletionWarning = true"
            @hide="switchVisibility(activeJD)"
            @resetFocus="resetFocus"
          />
        </v-col>
      </v-row>
    </v-container>

    <v-overlay
      :value="showDeletionWarning"
      :dark="false"
      style="z-index: 12 !important"
    >
      <deletionWarning
        v-if="activeJD"
        :data="activeJD.title"
        item=" die Stellenanzeige"
        @cancel="showDeletionWarning = false"
        @confirm="deleteJD"
      />
    </v-overlay>

    <v-overlay
      :value="showEditView"
      :dark="false"
      style="z-index: 12 !important"
    >
      <jdEdit
        :jdData="editedJD"
        :companies="getCompanies"
        @close="closeEditView"
      />
    </v-overlay>

    <mobileResultDetail
      @edit="openEditView(activeJD)"
      @delete="showDeletionWarning = true"
      @hide="switchVisibility(activeJD)"
      :jobDescriptionData="activeJD"
      :isEditView="true"
      :editorView="true"
      :height="'100vh'"
      :open="showMobileJDDetail"
      @closeDrawer="showMobileJDDetail = 0"
    />

    <v-overlay :value="loading">
      <v-progress-circular indeterminate color="accent"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
const axios = require("axios");

import resultCard from "../../components/JobDescriptions/ResultCard_Backoffice.vue";
import resultDetail from "../../components/JobDescriptions/ResultDetail_Backoffice.vue";

import deletionWarning from "../../components/Main/DeletionWarning.vue";
import jdEdit from "../../components/Backoffice/JobDescription/JobDescriptionEdit.vue";

import mobileResultDetail from "../../components/JobDescriptions/ResultDetail_MobileDrawer_Backoffice.vue";

export default {
  name: "editJDList",

  data() {
    return {
      jobDescriptions: [],
      activeJD: undefined,
      editedJD: undefined,

      searchString: undefined,

      showDeletionWarning: false,
      showEditView: false,

      showMobileJDDetail: 0,

      page: 1,
      maxPages: undefined,

      totalJDs: 0,

      loading: false,
    };
  },

  components: {
    resultCard,
    resultDetail,
    mobileResultDetail,

    deletionWarning,
    jdEdit,
  },

  watch: {
    page() {
      this.getAllJD();
    },
    searchString() {
      if (this.searchString && this.searchString.length >= 3) {
        this.getJDByCompany(this.searchString);
      } else {
        if (this.page != 1) {
          this.page = 1;
          this.getAllJD();
        } else {
          this.getAllJD();
        }
      }
    },
  },

  computed: {
    getCompanies() {
      if (this.editedJD) return [this.editedJD.Company];
      else return [];
    },
    maxWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "100vw";
        case "sm":
          return "90vw";
        case "md":
          return "85vw";
        case "lg":
          return "75vw";
        case "xl":
          return "65vw";
        default:
          return "50vw";
      }
    },
    getContainerHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "unset";
        case "sm":
          return "unset";
        case "md":
          return "70vh";
        case "lg":
          return "80vh";
        case "xl":
          return "80vh";
        default:
          return "80vh";
      }
    },
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        default:
          return false;
      }
    },
  },

  methods: {
    closeEditView(data) {
      this.showEditView = false;

      if (data) {
        this.getJDData(data.resp_id);
      }

      
    },
    showDetailView(jd) {
      this.activeJD = jd;

      if (this.isMobile) {
        this.showMobileJDDetail++;
      }
    },
    openEditView(jd) {
      this.editedJD = jd;

      this.showEditView = true;
    },
    deleteJD() {
      axios
        .delete(
          axios.defaults.baseURL + "jobdescriptions/delete/" + this.activeJD.id
        )
        .then(() => {
          this.getJDData();

          this.showDeletionWarning = false;
        })
        .catch((e) => {
          console.log(e);
          window.dispatchEvent(new CustomEvent("showErrorDisplay"));
        });
    },
    switchVisibility(jd) {
      this.loading = true;

      let publishDate;

      if (!jd.published) {
        let temp = new Date()
          .toLocaleString()
          .split(",")[0]
          .split(".");
        publishDate = temp[2] + "-" + temp[1] + "-" + temp[0];
      }

      axios
        .patch(axios.defaults.baseURL + "jobdescriptions/patch/" + jd.id, {
          id: jd.id,
          published: !jd.published,
          publish_date: publishDate,
          Company: jd.Company.id,
        })
        .then((resp) => {
          for (let i = 0; i < this.jobDescriptions.length; i++) {
            if (this.jobDescriptions[i].id == resp.data.id) {
              this.jobDescriptions[i].published = resp.data.published;

              break;
            }
          }

          this.getAllJD();

          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
          window.dispatchEvent(new CustomEvent("showErrorDisplay"));
        });
    },
    getJDData(id) {
      if (this.$route.params.firma) {
        if (this.searchString != this.$route.params.firma) {
          this.searchString = this.$route.params.firma;
        } else {
          this.getJDByCompany(this.searchString, id);
        }
      } else {
        this.getAllJD(id);
      }
    },
    getJDByCompany(companyName, id) {
      let escapedString = companyName.replaceAll("&", "%26");
      axios
        .get(
          axios.defaults.baseURL +
            "jobdescriptions/by/company/?company=" +
            escapedString
        )
        .then((resp) => {
          console.log(resp.data)
          this.jobDescriptions = resp.data;
          this.maxPages = 1;

          this.activeJD = this.jobDescriptions[0];

          this.activeJD = this.jobDescriptions[0];
          if (id) {
            for (let i = 0; i < this.jobDescriptions.length; i++) {
              if (this.jobDescriptions[i].id == id) {
                this.activeJD = this.jobDescriptions[i];
                break;
              }
            }
          }

          document.getElementById("scrollBox").scrollTop = 0;
        })
        .catch((e) => {
          console.log(e);
          window.dispatchEvent(new CustomEvent("showErrorDisplay"));
        });
    },
    getAllJD(id) {
      let query;
      if (this.searchString) {
        query = "?company=" + this.searchString + "&page=" + this.page;
      } else {
        query = "?page=" + this.page;
      }

      axios
        .get(axios.defaults.baseURL + "jobdescriptions/" + query)
        .then((resp) => {
          console.log(resp.data.results)
          this.jobDescriptions = resp.data.results;
          this.totalJDs = resp.data.count;
          this.maxPages =
            resp.data.count % 20 == 0
              ? resp.data.count / 20
              : Math.floor(resp.data.count / 20) + 1;

          if (this.activeJD) {
            let found = false;
            for (let i = 0; i < this.jobDescriptions.length; i++) {
              if (this.jobDescriptions[i].id == this.activeJD.id) {
                this.activeJD = this.jobDescriptions[i];
                found = true;
              }
            }

            if (!found) {
              this.activeJD = this.jobDescriptions[0];
            }
          } else {
            this.activeJD = this.jobDescriptions[0];

            this.activeJD = this.jobDescriptions[0];
          if (id) {
            for (let i = 0; i < this.jobDescriptions.length; i++) {
              if (this.jobDescriptions[i].id == id) {
                this.activeJD = this.jobDescriptions[i];
                break;
              }
            }
          }
          }

          document.getElementById("scrollBox").scrollTop = 0;
        })
        .catch((e) => {
          console.log(e);
          window.dispatchEvent(new CustomEvent("showErrorDisplay"));
        });
    },
    rerouteFocus(e) {
      document.getElementById("paginator").focus();
      e.stopPropagation();
      e.preventDefault();
    },
    resetFocus() {
      for (let i = 0; i < this.jobDescriptions.length; i++) {
        if (this.jobDescriptions[i].id == this.activeJD.id) {
          if (i < this.jobDescriptions.length - 1) {
            document
              .getElementById(this.jobDescriptions[i + 1].id + "_card")
              .focus();
          } else {
            document
              .getElementById(this.jobDescriptions[0].id + "_card")
              .focus();
          }

          return;
        }
      }
    },
    switchFocus() {
      document.getElementById("resultDetail").focus();
    },
  },

  mounted() {
    this.getJDData();
  },
};
</script>
