<template>
  <div>
    <v-container
      class="pa-1"
      style="margin: 0 auto"
      :style="{
        'max-width': maxWidth,
        width: status != undefined ? 'fit-content' : 'unset',
      }"
    >
      <v-row>
        <v-col>
          <video
            style="width: inherit"
            controls
            preload="none"
            poster="../../assets/images/video_posters/IHK_Halle_2023.png"
          >
            <source src="../../assets/videos/IHK_Halle_11154_V03.mp4" type="video/mp4" />
            Ihr Browser unterstützt das Video-Tag nicht.
          </video>
        </v-col>
      </v-row>
    </v-container>
    <v-card
      flat
      class="my-8 pa-4"
      style="margin: 0 auto"
      :style="{
        'max-width': maxWidth,
        width: status != undefined ? 'fit-content' : 'unset',
      }"
    >
      <v-container v-if="status == undefined">
        <v-form ref="registerForm">
          <v-row>
            <v-spacer />
            <v-col cols="auto">
              <h1 class="text--center encodeFont">Ihr Unternehmen registrieren</h1>
            </v-col>
            <v-spacer />
          </v-row>

          <br />

          <v-row class="pa-0 ma-0">
            <v-col class="py-0 my-0">
              <v-text-field
                :rules="[required, maxLength50]"
                label="Firmenname*"
                outlined
                v-model="companyName"
              >
              </v-text-field>
            </v-col>
            <v-col class="py-0 my-0">
              <v-text-field label="Webseite" outlined v-model="weblink"> </v-text-field>
            </v-col>
          </v-row>

          <AddressComponentVue
            :validateNow="validateNow"
            @formValid="validAddressForm"
            :isRequired="true"
            :addressData="address"
            :disabled="false"
          />
          
          <v-row class="pa-0 ma-0">
            <v-col class="py-0 my-0" :cols="smallScreenBreak ? 12 : 2">
              <v-select label="Anrede" outlined v-model="salutation" :items="salutations">
              </v-select>
            </v-col>

            <v-col class="py-0 my-0" :cols="smallScreenBreak ? 12 : 5">
              <v-text-field
                :rules="[required, maxLength30]"
                label="Kontaktperson Vorname*"
                outlined
                v-model="firstName"
              >
              </v-text-field>
            </v-col>
            <v-col class="py-0 my-0" :cols="smallScreenBreak ? 12 : 5">
              <v-text-field
                :rules="[required, maxLength30]"
                label="Kontaktperson Nachname*"
                outlined
                v-model="lastName"
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row class="pa-0 ma-0">
            <v-col class="py-0 my-0" :cols="smallScreenBreak ? 12 : 6">
              <v-text-field
                :rules="[required, maxLength20]"
                label="Telefon*"
                outlined
                v-model="phone"
              >
              </v-text-field>
            </v-col>

            <v-col class="py-0 my-0" :cols="smallScreenBreak ? 12 : 6">
              <v-text-field
                :rules="[required, maxLength50]"
                label="E-Mail*"
                outlined
                v-model="mail"
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row class="pa-0 ma-0">
            <v-spacer />
            <v-col cols="12">
              <v-btn
                @click="register"
                block
                large
                style="background-color: var(--v-primary-base) !important"
              >
                Jetzt Registrieren
              </v-btn>
            </v-col>
            <v-spacer />
          </v-row>
        </v-form>
      </v-container>

      <v-container v-else-if="status == 1">
        <v-row>
          <v-spacer />
          <v-col cols="12" align="center">
            <h1 class="encodeFont">Etwas ist schief gegangen ...</h1>
            <br />
            <p>Bitte versuchen Sie es später noch einmal</p>
          </v-col>
          <v-spacer />
        </v-row>
        <v-row>
          <v-spacer />
          <v-col cols="12">
            <v-btn block large @click="gotoMain" class="primaryButton py-3">
              Zurück zur Startseite
            </v-btn>
          </v-col>
          <v-spacer />
        </v-row>
      </v-container>

      <v-container v-else-if="status == 0">
        <v-row>
          <v-spacer />
          <v-col cols="12" align="center">
            <h1 class="encodeFont">Wir haben Ihre Anfrage erhalten</h1>
            <br />
            <p>
              Sie werden in Kürze per Mail benachrichtigt, wenn Ihr Betrieb freigegeben
              wurde
            </p>
          </v-col>
          <v-spacer />
        </v-row>
        <v-row>
          <v-spacer />
          <v-col cols="6">
            <v-img src="../../assets/images/icons/mail-sent.png"></v-img>
          </v-col>
          <v-spacer />
        </v-row>
        <v-row>
          <v-spacer />
          <v-col cols="12">
            <v-btn block large @click="gotoMain" class="primaryButton py-3">
              Zurück zur Startseite
            </v-btn>
          </v-col>
          <v-spacer />
        </v-row>
      </v-container>
    </v-card>

    <v-overlay :value="loading">
      <v-progress-circular indeterminate color="accent"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import AddressComponentVue from "../../components/Main/Address.vue";

const axios = require("axios");

export default {
  name: "register",

  data() {
    return {
      companyName: undefined,

      address: {
        id: undefined,
        City: {
          city: undefined,
          postcode: undefined,
          id: undefined,
        },
        street: undefined,
        additional: undefined,
      },

      firstName: undefined,
      lastName: undefined,
      phone: undefined,
      mail: undefined,
      weblink: undefined,

      salutations: ["Divers", "Frau", "Herr"],
      salutation: undefined,

      status: undefined, //0: ok, 1: error

      loading: false,

      validateNow: 0,
      addressFormIsValid: false,

      maxLength50: (value) => !value || value.length <= 50 || "Zu lang!",
      maxLength30: (value) => !value || value.length <= 30 || "Zu lang!",
      maxLength20: (value) => !value || value.length <= 20 || "Zu lang!",
      maxLength5: (value) => !value || value.length <= 5 || "Zu lang!",
      required: (value) => !!value || "Pflichtfeld!",
      integer: (value) => (!!value && !isNaN(parseInt(value))) || "Valide PLZ eingeben!",
    };
  },

  computed: {
    maxWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "100vw";
        case "sm":
          return "90vw";
        case "md":
          return "75vw";
        case "lg":
          return "55vw";
        case "xl":
          return "45vw";
        default:
          return "50vw";
      }
    },
    smallScreenBreak() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return false;
        default:
          return false;
      }
    },
  },

  components: {
    AddressComponentVue,
  },

  methods: {
    validAddressForm(data) {
      this.addressFormIsValid = data;
    },
    gotoMain() {
      this.$router.push("/");
    },

    register() {
      ++this.validateNow;

      setTimeout(() => {
        if (this.$refs.registerForm.validate() && this.addressFormIsValid) {
          this.loading = true;

          let formData = {
            name: this.companyName,
            email: this.mail,
            phone: this.phone,
            weblink: this.weblink,
            salutation: this.salutations.indexOf(this.salutation) + 1,
            first_name: this.firstName,
            last_name: this.lastName,
            street: this.address.street,
            additional: this.address.additional,
            City: this.address.City.city.id,
          };

          axios
            .post(axios.defaults.baseURL + "companies/request/post/", formData)
            .then(() => {
              this.status = 0;

              this.loading = false;
            })
            .catch((e) => {
              console.log(e);
              this.status = 1;
              window.dispatchEvent(new CustomEvent("showErrorDisplay"));
              this.loading = false;
            });
        }
      }, 250);
    },
  },

  mounted() {
    this.$refs.registerForm.reset();
    this.salutation = this.salutations[0]
  },
};
</script>
