<template>
  <div id="addressComponent">
    <v-form ref="addressForm">
      <v-row class="ma-0 pa-0">
        <v-col
          class="py-0"
          :class="{ 'pl-0': removePadding, 'pr-0': isMobile }"
          :cols="isMobile ? 12 : 6"
        >
          <v-text-field
            :disabled="disabled"
            label="Straße&Hausnr.*"
            v-model="address.street"
            outlined
            :rules="[address_required, max50]"
          ></v-text-field>
        </v-col>
        <v-col
          class="py-0"
          :cols="isMobile ? 12 : 6"
          :class="{ 'pr-0': removePadding, 'pl-0': isMobile }"
        >
          <v-text-field
            :disabled="disabled"
            label="Addresszusatz"
            v-model="address.additional"
            :rules="[max50]"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="ma-0 pa-0">
        <v-col class="py-0" :class="{ 'pl-0': removePadding, 'pr-0': isMobile }">
          <v-combobox
            label="PLZ*"
            v-model="address.City.postcode"
            outlined
            :rules="[address_required, validPostcode]"
            :items="postcodes"
            return-object
            item-text="postcode"
            :search-input.sync="postcode"
            :disabled="disabled"
          >
          </v-combobox>
        </v-col>
        <v-col
          class="py-0"
          :cols="isMobile ? 12 : 6"
          :class="{ 'pr-0': removePadding, 'pl-0': isMobile }"
        >
          <v-select
            label="Ort*"
            v-model="address.City.city"
            outlined
            :rules="[address_required]"
            :items="cities"
            return-object
            item-text="city"
            :disabled="disabled"
          >
            <template v-slot:item="{ item }">
              <v-container>
                <v-row>
                  {{ item.city }}
                </v-row>
              </v-container>
            </template>
          </v-select>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
const axios = require("axios");

export default {
  name: "addressComponent",

  props: ["isRequired", "addressData", "disabled", "validateNow", "removePadding"],

  data() {
    return {
      cities: [],
      postcodes: [],

      address: {
        id: null,
        City: {
          city: null,
          postcode: null,
          id: null,
        },
        street: null,
        additional: null,
      },

      postcode: undefined,
      city: undefined,

      address_required: (value) => !this.isRequired || !!value || "Pflichtfeld!",
      max50: (value) => !value || value.length <= 50 || "Max. 50 Zeichen!",
      validPostcode: (value) =>
        !this.isRequired || this.checkIncludesPostcode(value) || "Keine valide PLZ!",
    };
  },

  watch: {
    addressData() {
      this.address = this.addressData;

      this.$refs.addressForm.resetValidation();
    },
    postcode() {
      this.getPostcodes();

      if (this.postcode && this.postcode?.length > 0) {
        this.getCitiesByPostcode();
      }
    },
    isRequired() {
      this.$refs.addressForm.validate();
    },
    validateNow() {
      if (this.$refs.addressForm.validate()) {
        this.$emit("formValid", true);
      } else {
        this.$emit("formValid", false);
      }
    },
  },

  computed: {
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        default:
          return false;
      }
    },
  },

  methods: {
    checkIncludesPostcode(value) {
      if (this.postcode) {
        for (let i = 0; i < this.postcodes.length; i++) {
          if (this.postcodes[i].postcode == value.postcode) {
            return true;
          } else if (this.postcodes[i].postcode == value) {
            this.address.City.postcode = this.postcodes[i];
          }
        }
      }

      return false;
    },
    getPostcodes() {
      console.log("getPostCodes");
      axios
        .get(
          axios.defaults.baseURL +
            "cities/postcode/?search=" +
            (this.postcode == null ? "" : this.postcode) //+
          // "&city=" +
          // (this.city == null ? "" : this.city)
        )
        .then((resp) => {
          this.postcodes = resp.data;

          for (let i = 0; i < this.postcodes.length; i++) {
            if (this.postcodes[i].postcode == this.postcode) {
              this.address.City.id = this.postcodes[i].id;
            }
          }

          if (this.postcode) {
            this.checkIncludesPostcode(this.postcode);
          }
        })
        .catch((e) => {
          console.log(e);
          window.dispatchEvent(new CustomEvent("showErrorDisplay"));
        });
    },
    getCities() {
      axios
        .get(axios.defaults.baseURL + "cities/name/?search=" + this.city)
        .then((resp) => {
          // this.cities = resp.data;
          this.cities = [];

          let temp = {};

          for (let i = 0; i < resp.data.length; i++) {
            temp[resp.data[i].city] = resp.data[i].state;
          }

          for (let i in temp) {
            this.cities.push({
              city: i,
              state: temp[i],
            });
          }
        })
        .catch((e) => {
          console.log(e);
          window.dispatchEvent(new CustomEvent("showErrorDisplay"));
        });
    },
    getCitiesByPostcode() {
      axios
        .get(axios.defaults.baseURL + "cities/postcode/?search=" + this.postcode)
        .then((resp) => {
          // this.cities = resp.data;
          this.cities = resp.data;

          if (typeof this.postcode == "string" && this.cities.length == 1) {
            console.log(this.address);
            this.address.City.city = this.cities[0];
          }
        })
        .catch((e) => {
          console.log(e);
          window.dispatchEvent(new CustomEvent("showErrorDisplay"));
        });
    },
  },

  // updated() {
  //   if (this.$refs.addressForm.validate()) {
  //     this.$emit("formValid", true);
  //   } else {
  //     this.$emit("formValid", false);
  //   }
  // },

  mounted() {
    this.address = this.addressData;

    if (this.postcode) {
      this.getPostcodes();
    }
  },
};
</script>
