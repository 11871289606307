<template>
  <div>
    <v-card
      flat
      style="
        min-height: 50vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      "
      class="scrollable "
      :style="{
        width: cardWidth,
        height: cardHeight != undefined ? cardHeight : 'auto',
        'max-height': cardHeight == 'auto' ? 'unset' : '80vh',
      }"
      :class="{ 'pa-8': !setupView }"
    >
      <div>
        <h2 class="encodeFont">Stellenanzeigen von externen Webseiten übernehmen?</h2>
        <p>
          Wir konnten Ihrem Unternehmen folgende Stellenanzeigen externer Anbieter
          zuordnen. Möchten Sie diese offiziell in Ihr Unternehmensprofil übernehmen?
        </p>

        <div v-for="(jd, index) in jobDescriptions" :key="index">
          <v-card flat>
            <v-container fluid>
              <v-row>
                <v-col
                  :cols="9"
                  class="lightOutline"
                  :class="{ primaryOutline: jd.accepted, disabledCard: !jd.accepted }"
                >
                  <v-container fluid>
                    <v-row class="ma-0 py-0">
                      <v-col class="ma-0 py-0">
                        <b>{{ jd.Job_name.name }}</b>
                      </v-col>
                    </v-row>
                    <v-row class="ma-0 py-0">
                      <v-col class="ma-0 py-0">
                        von: {{ jd.external ? jd.external : "hallo-beruf.de" }}
                      </v-col>
                    </v-row>
                    <v-row class="ma-0 pa-0">
                      <v-col cols="auto" class="ma-0 py-2">
                        <v-chip>
                          {{ jd.job_type }}
                        </v-chip>
                      </v-col>
                      <v-col cols="auto" class="ma-0 py-2">
                        <v-chip>
                          Beginn der Stelle: {{ jd.job_start | moment("DD.MM.YY") }}
                        </v-chip>
                      </v-col>
                      <v-col v-if="jd.job_end" cols="auto" class="ma-0 py-2">
                        <v-chip> Bis: {{ jd.job_end | moment("DD.MM.YY") }} </v-chip>
                      </v-col>
                      <v-col v-else cols="auto" class="ma-0 py-2">
                        <v-chip> Bis: -- </v-chip>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
                <v-spacer />
                <v-col :cols="2" class="px-0" style="display: flex; align-items: center">
                  <v-switch @click="switchJD(jd)" inset></v-switch>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
          <hr class="my-3" v-if="index != jobDescriptions.length - 1" />
        </div>

        <div v-if="!jobDescriptions || jobDescriptions.length == 0">
          <p><b>Wir konnten keine Stellenanzeigen von anderen Börsen finden.</b></p>
        </div>
      </div>

      <v-container>
        <v-row>
          <v-spacer />
          <v-col :cols="isMobile ? 12 : 'auto'" class="px-0">
            <v-btn
              @click="acceptSetup()"
              class="primaryButton py-4"
              large
              block
              style="margin: 0 auto"
            >
              Unternehmenssetup <br v-if="isMobile" />
              abschließen
            </v-btn>
          </v-col>
          <v-spacer />
        </v-row>
      </v-container>
    </v-card>

    <v-overlay :value="loading">
      <v-progress-circular indeterminate color="accent"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
const axios = require("axios");

export default {
  name: "importJDList",

  props: ["setupView", "cardHeight", "jobDescriptions"],

  data() {
    return {
      loading: false,
    };
  },

  components: {},

  computed: {
    cardWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "85vw";
        case "sm":
          return "75vw";
        case "md":
          return "65vw";
        case "lg":
          return "55vw";
        case "xl":
          return "45vw";
        default:
          return "65vw";
      }
    },
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        default:
          return false;
      }
    },
  },

  methods: {
    switchJD(jd) {
      jd.accepted = !jd.accepted;
      this.$forceUpdate();

      this.$emit("update", { id: jd.id, val: jd.accepted });
    },
    acceptSetup() {
      this.patchJD(0);

      this.$emit("startLoad");
      // this.$emit("finish")
    },

    patchJD(index) {
      if (!this.jobDescriptions || !this.jobDescriptions[index]) {
        this.loading = false;
        this.$emit("finish");
        return;
      }

      if (this.jobDescriptions[index].accepted) {
        axios
          .patch(
            axios.defaults.baseURL +
              "jobdescriptions/patch/" +
              this.jobDescriptions[index].id,
            {
              id: this.jobDescriptions[index].id,
              external: "",
            }
          )
          .then(() => {
            this.patchJD(++index);
          })
          .catch((e) => {
            console.log(e);
            window.dispatchEvent(new CustomEvent("showErrorDisplay"));
            this.$emit("error");
          });
      } else {
        axios
          .delete(
            axios.defaults.baseURL +
              "jobdescriptions/delete/" +
              this.jobDescriptions[index].id
          )
          .then(() => {
            this.patchJD(++index);
          })
          .catch((e) => {
            console.log(e);
            window.dispatchEvent(new CustomEvent("showErrorDisplay"));
            this.$emit("error");
          });
      }
    },
  },

  mounted(){
    
  }
};
</script>
