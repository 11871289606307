var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pl-0",attrs:{"cols":"6"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"encodeFont pa-0 pb-1"},[_c('v-text-field',{staticClass:"hideInputMessage px-4",attrs:{"underlined":"","hide-details":"","append-icon":"mdi-magnify","label":"Berufsbezeichnung eingeben"},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1)],1)],1),_c('v-col',{staticClass:"py-3",attrs:{"cols":"auto"}},[_c('MenuVue',{on:{"resetFilter":_vm.resetFilter}},[_c('v-container',[_c('v-row',{attrs:{"align":"center"},on:{"click":_vm.toggleBrafoFilter}},[_c('v-col',{staticStyle:{"border-radius":"50%"},style:({
                'background-color': _vm.filter.brafo
                  ? 'var(--v-accent2-lighten5)'
                  : 'transparent',
              }),attrs:{"cols":"auto"}},[_c('v-icon',{staticClass:"pa-0",attrs:{"size":"xx-large","color":_vm.filter.brafo == 1 ? 'primary' : ''}},[_vm._v("mdi-earth")])],1),_c('v-col',{attrs:{"cols":"auto"}},[_vm._v("BRAFO")])],1),_c('v-row',{attrs:{"align":"center"},on:{"click":_vm.toggleMappingFilter}},[_c('v-col',{staticStyle:{"border-radius":"50%"},style:({
                'background-color': _vm.filter.mapping
                  ? 'var(--v-accent2-lighten5)'
                  : 'transparent',
              }),attrs:{"cols":"auto"}},[_c('v-icon',{staticClass:"pa-0",attrs:{"size":"xx-large","color":_vm.filter.mapping == 1 ? 'primary' : ''}},[_vm._v("mdi-briefcase")])],1),_c('v-col',[_vm._v("vorhandener Beruf")])],1),_c('v-row',{attrs:{"align":"center"},on:{"click":_vm.toggleBlockedFilter}},[_c('v-col',{staticStyle:{"border-radius":"50%"},style:({
                'background-color': _vm.filter.blocked
                  ? 'var(--v-accent2-lighten5)'
                  : 'transparent',
              }),attrs:{"cols":"auto"}},[_c('v-icon',{staticClass:"pa-0",attrs:{"size":"xx-large","color":_vm.filter.blocked == 1 ? 'primary' : ''}},[_vm._v("mdi-cancel")])],1),_c('v-col',[_vm._v("gesperrt")])],1),_c('v-row',{attrs:{"align":"center"},on:{"click":_vm.toggleTodoFilter}},[_c('v-col',{staticStyle:{"border-radius":"50%"},style:({
                'background-color': _vm.filter.todo
                  ? 'var(--v-accent2-lighten5)'
                  : 'transparent',
              }),attrs:{"cols":"auto"}},[_c('v-icon',{staticClass:"pa-0",attrs:{"size":"xx-large","color":_vm.filter.todo == 1 ? 'primary' : ''}},[_vm._v("mdi-dots-square")])],1),_c('v-col',[_vm._v("zu bearbeiten")])],1)],1)],1)],1),_c('v-spacer'),_c('v-col',{staticClass:"pa-0 py-3 pt-4",attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"primaryOnWhiteButton",staticStyle:{"width":"64px"},attrs:{"depressed":"","large":"","block":""},on:{"click":_vm.showNewJobOverlay}},[_c('v-icon',{attrs:{"size":"xx-large"}},[_vm._v(" mdi-plus ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }