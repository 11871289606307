<template>
  <div style="position: relative; height: inherit">
    <div ref="mapContainer" style="width: inherit; height: inherit;border-radius: 4px"></div>
    <v-btn
      @click="changeMapSize"
      style="
        position: absolute;
        right: 8px;
        bottom: 32px;
        z-index: 1;
        background-color: #ffffff !important;
      "
    >
      <v-icon v-if="smallMap" color="primary" size="xx-large">mdi-chevron-down</v-icon>
      <v-icon v-else color="primary" size="xx-large">mdi-chevron-up</v-icon>
    </v-btn>
    <div
      style="
        position: absolute;
        left: 8px;
        top: 8px;
        z-index: 1;
        background-color: #ffffff;
      "
      class="pa-2"
    >
      <div style="display: flex; align-items: center" class="mb-2">
        <v-icon color="accent" class="mr-2"> mdi-circle </v-icon>
        <b>Ausbildung</b>
      </div>
      <div style="display: flex; align-items: center" class="mb-2">
        <v-icon color="primary" class="mr-2"> mdi-circle </v-icon>
        <b>Praktikum</b>
      </div>
      <div style="display: flex; align-items: center">
        <v-icon color="#424242" class="mr-2"> mdi-circle </v-icon> <b>Event</b>
      </div>
    </v-card>
    <v-btn
      v-if="isMobile"
      style="
        position: absolute;
        left: 8px;
        bottom: 32px;
        z-index: 1;
        background-color: #ffffff !important;
      "
      @click="toggleFavorites"
    >
      <v-icon color="primary" v-if="!showFavorites"> mdi-star-outline </v-icon>
      <v-icon color="primary" v-if="showFavorites"> mdi-star </v-icon>
    </v-btn>
  </div>
</template>

<script>
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

import mapMarkerIntern from "../../assets/images/map_marker_dark.png";
import mapMarkerApprentice from "../../assets/images/map_marker_light.png";
import mapMarkerEvent from "../../assets/images/map-marker-event.png";

const axios = require("axios");

export default {
  name: "MapboxMap",
  props: ["smallMap", "refreshDisplay", "showFavorites", "selectedJD", "selectedEvent"],
  data() {
    return {
      map: null,
      center: [11.62916, 52.12773],
      eventPoints: {
        type: "geojson",
        data: { type: "FeatureCollection", features: [] },
        cluster: false,
      },
      zoom: 7,
      leaves: undefined,
      job_descriptions: undefined,
      apprenticeships: {
        type: "geojson",
        data: { type: "FeatureCollection", features: [] },
        cluster: true,
        clusterMaxZoom: 18,
        clusterRadius: 50,
      },
      internships: {
        type: "geojson",
        data: { type: "FeatureCollection", features: [] },
        cluster: true,
        clusterMaxZoom: 18,
        clusterRadius: 50,
      },

      searchQ: undefined,
      circleRadius: [
        "step",
        ["get", "point_count"],
        20, //minimum size
        10,
        20, // min_point_count, circle-size
        25,
        20,
        50,
        20,
        100,
        25,
        1000,
        35,
      ],
    };
  },

  watch: {
    smallMap() {
      this.map.resize();
    },
    refreshDisplay() {
      this.fetchData();
    },
    selectedJD() {
      if (this.selectedJD) {
        if (this.selectedJD.job_type == "Ausbildung") {
          for (let i = 0; i < this.apprenticeships.data.features.length; i++) {
            if (
              this.apprenticeships.data.features[i].properties.id == this.selectedJD.id
            ) {
              let center = this.apprenticeships.data.features[i].geometry.coordinates;

              for (let l = 0; l < center.length; l++) {
                center[l] = parseFloat(center[l]).toFixed(5);
              }

              this.map.easeTo({
                duration: 750,
                center: center,
                zoom: 17,
              });

              break;
            }
          }
        } else if (this.selectedJD.job_type == "Praktikum") {
          for (let j = 0; j < this.internships.data.features.length; j++) {
            if (this.internships.data.features[j].properties.id == this.selectedJD.id) {
              let center = this.internships.data.features[j].geometry.coordinates;

              for (let k = 0; k < center.length; k++) {
                center[k] = parseFloat(center[k]).toFixed(5);
              }

              this.map.easeTo({
                duration: 750,
                center: center,
                zoom: 17,
              });
              break;
            }
          }
        }
      }
    },
    selectedEvent() {
      if (this.selectedEvent) {
        for (let i = 0; i < this.eventPoints.data.features.length; i++) {
          if (this.eventPoints.data.features[i].properties.id == this.selectedEvent.id) {
            let center = this.eventPoints.data.features[i].geometry.coordinates;
            this.map.easeTo({
              duration: 750,
              center: center,
              zoom: 17,
            });
          }
        }
      }
    },
  },

  computed: {
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return false;
        default:
          return false;
      }
    },
  },

  methods: {
    buildMap() {
      if (this.map) {
        this.map.remove();
        this.map = null;
      }

      mapboxgl.accessToken =
        "pk.eyJ1Ijoicm9iYW9mZmljZSIsImEiOiJjbDk1amtxbHAxdnFrM3ZxbXRzdXV4MWFpIn0.OcnZ1R-wUkS97Lucoq9vIg";
      this.zoom = 6;

      if (this.$SearchFilterHandler.searchFilters.selectedCity) {
        let perimeter = this.$SearchFilterHandler.searchFilters.perimeter;
        if (perimeter == "Stadt" || perimeter == "10km") this.zoom = 10;
        else if (perimeter == "15km") this.zoom = 9;
        else if (perimeter == "25km") this.zoom = 9;
        else if (perimeter == "50km") this.zoom = 8;
        else if (perimeter == "100km") this.zoom = 7;
      }

      let center = this.center;
      if (
        this.$SearchFilterHandler.searchFilters.selectedCity &&
        this.$SearchFilterHandler.searchFilters.selectedCity.longitude
      ) {
        center = [
          this.$SearchFilterHandler.searchFilters.selectedCity.longitude,
          this.$SearchFilterHandler.searchFilters.selectedCity.latitude,
        ];
      }

      this.map = new mapboxgl.Map({
        container: this.$refs.mapContainer, // Container ID
        style: "mapbox://styles/mapbox/light-v10", // Style URL
        center: center, // Starting position [lng, lat]
        zoom: this.zoom, // Starting zoom level
      });

      this.map.on("load", () => {
        this.map.addSource("internships", this.internships);
        this.map.addSource("apprenticeships", this.apprenticeships);
        this.map.addSource("events", this.eventPoints);

        this.map.addLayer({
          id: "clusters-intern",
          type: "circle",
          source: "internships",
          filter: ["has", "point_count"],
          paint: {
            "circle-color": this.$vuetify.theme.themes.light.primary,

            "circle-radius": this.circleRadius,
            "circle-opacity": 0.4,
            "circle-stroke-width": 4,
            "circle-stroke-color": this.$vuetify.theme.themes.light.primary,
          },
        });

        this.map.addLayer({
          id: "cluster-count-intern",
          type: "symbol",
          source: "internships",
          filter: ["has", "point_count"],
          layout: {
            "text-field": ["get", "point_count_abbreviated"],
            "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
            "text-size": 18,
            "text-allow-overlap": true,
          },
          paint: {
            "text-color": "#000",
          },
        });

        this.map.loadImage(mapMarkerIntern, (err, image) => {
          if (err) {
            throw err;
          }
          this.map.addImage("markerIntern", image);
          this.map.addLayer({
            id: "unclustered-point-intern",
            type: "symbol",
            source: "internships",
            filter: ["!", ["has", "point_count"]],
            layout: {
              "icon-image": "markerIntern",
              "icon-size": 0.2,
              "icon-allow-overlap": true,
            },
          });
        });

        this.map.addLayer({
          id: "clusters",
          type: "circle",
          source: "apprenticeships",
          filter: ["has", "point_count"],
          paint: {
            "circle-color": this.$vuetify.theme.themes.light.accent,

            "circle-radius": this.circleRadius,
            "circle-opacity": 0.4,
            "circle-stroke-width": 4,
            "circle-stroke-color": this.$vuetify.theme.themes.light.accent,
          },
        });

        this.map.addLayer({
          id: "cluster-count",
          type: "symbol",
          source: "apprenticeships",
          filter: ["has", "point_count"],
          layout: {
            "text-field": ["get", "point_count_abbreviated"],
            "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
            "text-size": 18,
            "text-allow-overlap": true,
          },
          paint: {
            "text-color": "#000",
          },
        });

        this.map.loadImage(mapMarkerApprentice, (err, image) => {
          if (err) {
            throw err;
          }
          this.map.addImage("markerApprentice", image);
          this.map.addLayer({
            id: "unclustered-point",
            type: "symbol",
            source: "apprenticeships",
            filter: ["!", ["has", "point_count"]],
            layout: {
              "icon-image": "markerApprentice",
              "icon-size": 0.2,
              "icon-allow-overlap": true,
            },
          });
        });

        this.map.loadImage(mapMarkerEvent, (err, image) => {
          if (err) {
            throw err;
          }
          this.map.addImage("markerEvent", image);
          this.map.addLayer({
            id: "unclustered-point-event",
            type: "symbol",
            source: "events",
            filter: ["!", ["has", "point_count"]],
            layout: {
              "icon-image": "markerEvent",
              "icon-size": 0.2,
              "icon-allow-overlap": true,
            },
          });
        });

        this.map.on("click", "clusters", (e) => {
          const features = this.map.queryRenderedFeatures(e.point, {
            layers: ["clusters"],
          });

          const clusterId = features[0].properties.cluster_id;
          const pointCount = features[0].properties.point_count;

          this.map
            .getSource("apprenticeships")
            .getClusterExpansionZoom(clusterId, (err, zoom) => {
              if (err) return;

              let center = features[0].geometry.coordinates;
              if (zoom < 15) {
                for (let i = 0; i < center.length; i++) {
                  center[0] = parseFloat(center[0]).toFixed(5);
                  center[1] = parseFloat(center[1]).toFixed(5);
                }
                this.map.easeTo({
                  center: center,
                  zoom: zoom,
                });
              } else {
                this.map.easeTo({
                  center: center,
                  zoom: zoom,
                });
                this.getJobsFromClusterApprenticeships(clusterId, pointCount);
              }
            });
        });

        this.map.on("click", "clusters-intern", (e) => {
          const features = this.map.queryRenderedFeatures(e.point, {
            layers: ["clusters-intern"],
          });

          const clusterId = features[0].properties.cluster_id;
          const pointCount = features[0].properties.point_count;
          this.map
            .getSource("internships")
            .getClusterExpansionZoom(clusterId, (err, zoom) => {
              if (err) return;

              if (zoom < 15) {
                let center = features[0].geometry.coordinates;
                for (let i = 0; i < center.length; i++) {
                  center[0] = parseFloat(center[0]).toFixed(5);
                  center[1] = parseFloat(center[1]).toFixed(5);
                }
                this.map.easeTo({
                  center: center,
                  zoom: zoom,
                });
              } else {
                this.map.easeTo({
                  center: features[0].geometry.coordinates,
                  zoom: zoom,
                });
                this.getJobsFromClusterInternships(clusterId, pointCount);
              }
            });
        });

        this.map.on("click", "unclustered-point-event", (e) => {
          var coordinates = e.features[0].geometry.coordinates;
          this.map.easeTo({
            duration: 750,
            center: coordinates,
            zoom: 17,
          });

          this.$emit("eventMarkerClicked", e.features[0].properties.id);
        });

        this.map.on("click", "unclustered-point", (e) => {
          var coordinates = e.features[0].geometry.coordinates;
          this.map.easeTo({
            duration: 750,
            center: coordinates,
            zoom: 17,
          });

          this.$emit("fetchJobs", [e.features[0].properties.id]);
        });

        this.map.on("click", "unclustered-point-intern", (e) => {
          var coordinates = e.features[0].geometry.coordinates;
          this.map.easeTo({
            duration: 750,
            center: coordinates,
            zoom: 17,
          });

          this.$emit("fetchJobs", [e.features[0].properties.id]);
        });
      });

      this.map.addControl(new mapboxgl.NavigationControl());
    },

    getLeavesOfClusterApprenticeships(clusterId, pointCount) {
      return new Promise((resolve, reject) => {
        const source = this.map.getSource("apprenticeships");
        if (source) {
          source.getClusterLeaves(clusterId, pointCount, 0, (err, leaves) => {
            if (err) {
              console.error("Error fetching cluster leaves:", err);
              reject();
            }
            this.leaves = leaves;
            resolve();
          });
        }
      });
    },

    getLeavesOfClusterInternships(clusterId, pointCount) {
      return new Promise((resolve, reject) => {
        const source = this.map.getSource("internships");
        if (source) {
          source.getClusterLeaves(clusterId, pointCount, 0, (err, leaves) => {
            if (err) {
              console.error("Error fetching cluster leaves:", err);
              reject();
            }
            this.leaves = leaves;
            resolve();
          });
        }
      });
    },

    changeMapSize() {
      this.$emit("toggleSize");
    },

    getJobsFromClusterApprenticeships(clusterId, pointCount) {
      this.getLeavesOfClusterApprenticeships(clusterId, pointCount)
        .then(() => {
          let ids = [];

          if (this.leaves) {
            for (let i = 0; i < this.leaves.length; i++) {
              ids.push(this.leaves[i].properties.id);
            }
          }

          this.$emit("fetchJobs", ids);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getJobsFromClusterInternships(clusterId, pointCount) {
      this.getLeavesOfClusterInternships(clusterId, pointCount)
        .then(() => {
          let ids = [];

          if (this.leaves) {
            for (let i = 0; i < this.leaves.length; i++) {
              ids.push(this.leaves[i].properties.id);
            }
          }

          this.$emit("fetchJobs", ids);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    fetchData(ids = undefined) {
      this.searchQ = this.$SearchFilterHandler.getSearchQ();

      if (!this.searchQ) {
        this.searchQ = "?search_multi_match=";
      }

      if (this.showFavorites) {
        this.searchQ =
          "?search_multi_match=&job_ids=" +
          (localStorage.getItem("favoriteJDs")
            ? localStorage.getItem("favoriteJDs")
            : "0");
      }

      if (ids) {
        this.searchQ = "?search_multi_match=&job_ids=" + ids;
      }

      this.apprenticeships.data.features = [];
      this.internships.data.features = [];
      this.eventPoints.data.features = [];

      axios
        .get(axios.defaults.baseURL + "events")
        .then((resp) => {
          for (let i = 0; i < resp.data.length; i++) {
            if (resp.data[i].Company) {
              let geoData = {
                type: "Feature",
                properties: {
                  type: "event",
                  id: resp.data[i].id,
                },
                geometry: {
                  type: "Point",
                  coordinates: [
                    resp.data[i].Company.Address.longitude,
                    resp.data[i].Company.Address.latitude,
                  ],
                },
              };
              this.eventPoints.data.features.push(geoData);
            } else {
              let geoData = {
                type: "Feature",
                properties: {
                  type: "event",
                  id: resp.data[i].id,
                },
                geometry: {
                  type: "Point",
                  coordinates: [
                    resp.data[i].Address.longitude,
                    resp.data[i].Address.latitude,
                  ],
                },
              };
              this.eventPoints.data.features.push(geoData);
            }
          }

          axios
            .get(axios.defaults.baseURL + "mapbox/points/" + this.searchQ)
            .then((resp) => {
              for (let i = 0; i < resp.data.results.length; i++) {
                if (resp.data.results[i].job_type_int == 1) {
                  this.internships.data.features.push(resp.data.results[i].geo_data);
                } else if (resp.data.results[i].job_type_int == 2) {
                  this.apprenticeships.data.features.push(resp.data.results[i].geo_data);
                } else {
                  console.log("not a valid job_type");
                }
              }
              this.buildMap();
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    search() {
      this.page = 1;

      this.fetchData();

      this.searchPanel = [];
    },
    toggleFavorites() {
      this.$emit("toggleFavorites", !this.showFavorites);
    },
  },

  mounted() {
    const url = window.location.href.split("/")[
      window.location.href.split("/").length - 1
    ];
    // this.$SearchFilterHandler.setSearchQ(url);

    const test = new URLSearchParams(atob(url));
    if (test.get("job_ids")) {
      this.fetchData(test.get("job_ids"));
    }
    // this.search();
  },

  unmounted() {
    if (this.map) {
      this.map.remove();
      this.map = null;
    }
  },
};
</script>
