<template>
  <div>
    <v-navigation-drawer
      app
      class="pa-0 pb-0 scrollable hiddenScroll"
      right
      temporary
      style="overflow-x: hidden; width: 100vw; background-color:white;"
      v-model="drawer"
      :style="{height: '100vh'}"
    >

      <detailCard :favorites="favorites" @addFavorite="addFavorite" @removeFavorite="removeFromFavorites" @hide="switchVisibility" @edit="showEditView" @delete="showDeleteView" :jobDescriptionData="jobDescriptionData" :isEditView="isEditView" @close="closeDrawer" />
    </v-navigation-drawer>
  </div>
</template>

<script>
import detailCard from "./ResultDetail.vue"

export default {
  name: "searchByField",

  props: ["open", "jobDescriptionData", "isEditView", "favorites"],

  data() {
    return {
      drawer: false,
    };
  },

  components: {
    detailCard
  },

  watch: {
    open() {
      if (this.open > 0) this.drawer = true;
    },
  },

  computed: {
    
  },

  methods: {
    switchVisibility() {
      this.$emit("hide");
    },
    showEditView() {
      this.$emit("edit");
    },
    showDeleteView() {
      this.$emit("delete");
    },
    closeDrawer() {
      this.drawer = false;

      this.$emit("closeDrawer");
    },
    addFavorite(id){
      console.log("drawer id", id)
      this.$emit("addFavorite", id)
    },
    removeFromFavorites(id) {
      this.$emit("removeFavorite", id);
    },
  },

  updated(){
    if(!this.drawer){
      this.closeDrawer();
    }
  }
};
</script>
