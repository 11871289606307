<template>
  <div class="mb-16" style="width: 100%">
    <v-container fluid style="margin: 0 auto">
      <v-row class="mb-0">
        <v-col :cols="isMobile ? 12 : 12" style="display: flex" class="pa-0">
          <v-container fluid class="pa-0">
            <v-row class="ma-0">
              <v-col cols="12" class="py-0">
                <JobNameFilter
                  @search="setSearchQ"
                  @toggleFilter="toggleFilter"
                  @create="showNewJobOverlay"
                />
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col cols="auto" class="py-0 pl-7">
                <small>{{ resultCount }} Treffer</small>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
      <v-row v-for="(jobName, index) in jobNames" :key="index">
        <v-col cols="12" class="pa-0">
          <JobNameMapper
            :jobName="jobName"
            @addBrafo="showBrafoOverlay"
            @addJob="showJobsOverlay"
            @blockJobName="blockJobName"
          />
        </v-col>
      </v-row>

      <v-row class="mt-3 pa-0" v-if="maxPages">
        <v-spacer />
        <v-col class="ma-0 pa-0" cols="8">
          <v-pagination
            id="paginator"
            class="mt-4 focusableNoBorder"
            tabindex="0"
            v-model="page"
            :length="maxPages"
            :total-visible="9"
          ></v-pagination>
        </v-col>
        <v-spacer />
      </v-row>
    </v-container>

    <v-overlay v-if="showNewJob" :dark="false" style="z-index: 12 !important">
      <NewJobName @confirm="postNewJob" :brafos="brafos" @close="closeNewJob" />
    </v-overlay>

    <v-overlay v-if="showBrafo" :dark="false" style="z-index: 12 !important">
      <JobBrafoOverlay
        @confirm="confirmBrafoMapping"
        :brafos="brafos"
        @close="closeBrafo"
        :jobName="activeJobName"
      />
    </v-overlay>

    <v-overlay v-if="showJobs" :dark="false" style="z-index: 12 !important">
      <JobMappingOverlay
        @confirm="confirmNameMapping"
        @close="closeJobs"
        :jobName="activeJobName"
      />
    </v-overlay>

    <v-overlay :value="loading">
      <v-progress-circular indeterminate color="accent"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import JobBrafoOverlay from "@/components/Backoffice/Editor/JobNames/JobBrafoOverlay.vue";
import JobNameMapper from "../../components/Backoffice/Editor/JobNames/JobNameMapper.vue";
import JobMappingOverlay from "@/components/Backoffice/Editor/JobNames/JobMappingOverlay.vue";
import JobNameFilter from "@/components/Backoffice/Editor/JobNames/JobNameFilter.vue";
import NewJobName from "@/components/Backoffice/Editor/JobNames/NewJobName.vue";
const axios = require("axios");

export default {
  name: "jobNames",

  data() {
    return {
      jobNames: [],

      mappingNames: [], //map new jobnames at index of id

      brafos: [],
      mappingBrafos: [],

      activeJobName: undefined,

      showBrafo: 0,
      showJobs: 0,
      showMapping: 0,
      showNewJob: 0,

      page: 1,
      maxPages: 5,
      query: "",
      filter: {
        brafo: 0,
        mapping: 0,
        blocked: 0,
        todo: 0,
      },

      resultCount: 0,

      loading: false,
      radioGroup: [],
    };
  },

  watch: {
    page() {
      this.fetchJobNames(this.query, this.filter);
    },
  },

  components: {
    JobNameMapper,
    JobBrafoOverlay,
    JobMappingOverlay,
    JobNameFilter,
    NewJobName,
  },

  computed: {
    maxWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "100vw";
        case "sm":
          return "90vw";
        case "md":
          return "85vw";
        case "lg":
          return "75vw";
        case "xl":
          return "65vw";
        default:
          return "50vw";
      }
    },
    getContainerHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "unset";
        case "sm":
          return "unset";
        case "md":
          return "70vh";
        case "lg":
          return "80vh";
        case "xl":
          return "80vh";
        default:
          return "80vh";
      }
    },
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        default:
          return false;
      }
    },
  },

  methods: {
    // acceptMapping(jobNameId) {
    //   let formData = new FormData();

    //   formData.append("id", jobNameId);
    //   formData.append("external", false);

    //   if (this.radioGroup[jobNameId] == 1) {
    //     if (this.mappingNames[jobNameId]) {
    //       formData.append("Mapping", this.mappingNames[jobNameId].id);
    //     }
    //   } else if (this.radioGroup[jobNameId] == 2) {
    //     if (this.mappingBrafos[jobNameId]) {
    //       for (let i = 0; i < this.mappingBrafos[jobNameId].length; i++) {
    //         formData.append("Brafo", this.mappingBrafos[jobNameId][i].id);
    //       }
    //     }
    //   } else {
    //     formData.append("blocked", true);
    //   }

    //   axios
    //     .patch(axios.defaults.baseURL + "jobnames/patch/" + jobNameId, formData)
    //     .then((resp) => {
    //       for (let i = 0; i < this.jobNames.length; i++) {
    //         if (this.jobNames[i].id == resp.data.id) {
    //           this.jobNames.splice(i, 1);

    //           this.$forceUpdate();
    //         }
    //       }
    //     })
    //     .catch((e) => {
    //       console.log(e);
    //       window.dispatchEvent(new CustomEvent("showErrorDisplay"));
    //     });
    // },
    confirmNameMapping(jobName) {
      let id = undefined;
      if (jobName && jobName.id) id = jobName.id;
      else id = null;

      axios
        .patch(axios.defaults.baseURL + "jobnames/patch/" + this.activeJobName.id, {
          Mapping: id,
          external: false,
        })
        .then(() => {
          this.fetchJobNames(this.query, this.filter);
          this.closeJobs();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    confirmBrafoMapping(brafos) {
      if (!brafos) brafos = [];

      axios
        .patch(axios.defaults.baseURL + "jobnames/patch/" + this.activeJobName.id, {
          Brafo: brafos,
          external: false,
        })
        .then(() => {
          this.fetchJobNames(this.query, this.filter);
          this.closeBrafo();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    blockJobName(jobName) {
      axios
        .patch(axios.defaults.baseURL + "jobnames/patch/" + jobName.id, {
          blocked: !jobName.blocked,
        })
        .then((resp) => {
          this.fetchJobNames(this.query, this.filter);
          console.log(resp.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    showBrafoOverlay(jobName) {
      this.activeJobName = jobName;
      this.showBrafo++;
    },
    showJobsOverlay(jobName) {
      this.activeJobName = jobName;
      this.showJobs++;
    },
    showNewJobOverlay() {
      this.showNewJob++;
    },
    closeBrafo() {
      this.activeJobName = undefined;
      this.showBrafo = 0;
    },
    closeJobs() {
      this.activeJobName = undefined;
      this.showJobs = 0;
    },
    closeNewJob() {
      this.showNewJob = 0;
    },
    toggleFilter(query, filter) {
      this.query = query;
      this.filter = filter;
      this.fetchJobNames();
    },
    setSearchQ(data){
      console.log(data)
      this.query = data

      this.fetchJobNames()
    },
    fetchJobNames() {
      this.loading = true;
      this.jobNames = [];

      // if (query) this.page = 1;

      let params = "?page=" + this.page;

      if (this.query) {
        params += "&query=" + this.query;
      }

      params +=
        "&brafo=" +
        this.filter.brafo +
        "&mapping=" +
        this.filter.mapping +
        "&blocked=" +
        this.filter.blocked +
        "&todo=" +
        this.filter.todo;

      axios
        .get(axios.defaults.baseURL + "jobnames/filter" + params)
        .then((resp) => {
          this.maxPages =
            resp.data.count % 20 == 0
              ? resp.data.count / 20
              : Math.floor(resp.data.count / 20) + 1;
          this.resultCount = resp.data.count;

          for (let i = 0; i < resp.data.results.length; i++) {
            if (resp.data.results[i]) {
              this.jobNames.push(resp.data.results[i]);
            }
          }

          this.jobNames.sort(function (a, b) {
            if (a.external && !b.external) {
              return -1;
            } else if (b.external && !a.external) {
              return 1;
            } else {
              if (a.last_updated > b.last_updated) {
                return -1;
              } else {
                return 1;
              }
            }
          });

          this.loading = false;
        })
        .catch((e) => {
          this.page = 1;
          console.log(e);
          this.loading = false;
        });
    },
    postNewJob(newJob) {
      axios
        .post(axios.defaults.baseURL + "jobnames/create/", {
          name: newJob.name,
          // Mapping: newJob.mapping,
          Brafo: newJob.brafo,
          // blocked: newJob.blocked,
        })
        .then(() => {
          this.closeNewJob();
          this.fetchJobNames(this.query, this.filter);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },

  mounted() {
    this.loading = true;
    // this.fetchJobNames();

    axios
      .get(axios.defaults.baseURL + "brafo")
      .then((resp) => {
        this.brafos = resp.data;

        for (let i = 0; i < this.brafos.length; i++) {
          this.brafos[i].description =
            this.brafos[i].number + " - " + this.brafos[i].description;
        }
      })
      .catch((e) => {
        console.log(e);
        window.dispatchEvent(new CustomEvent("showErrorDisplay"));
      });
  },
};
</script>
