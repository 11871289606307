<template>
  <div>
    <v-form v-model="valid">
      <v-card style="width: 1000px" light>
        <v-container fluid>
          <v-row align="end" class="px-5">
            <v-col cols="10">
              <span style="font-size: 24px">Vorhandenen Beruf zuordnen</span>
            </v-col>
            <v-spacer />
            <v-col cols="auto">
            <v-btn
              style="position: absolute; right: 0%; top: 0%"
              class="mt-2 headerButton"
              text
              id="closeButton"
              @click="hideOverlay"
            >
              <v-icon size="x-large" color="black">mdi-close</v-icon>
            </v-btn>
          </v-col>
          </v-row>
          <v-row>
            <v-col class="px-8">
              <v-combobox
                label="Beruf"
                clearable
                outlined
                :items="jobNames"
                item-text="name"
                item-value="id"
                v-model="selected"
                :rules="validObject"
              >
                <template v-slot:selection="{ item }">
                  <v-chip v-if="item.name">{{ item.name }}</v-chip>
                </template>
              </v-combobox>
            </v-col>
          </v-row>
          <v-row class="ma-0 pa-0">
          <v-col
            :cols="isMobile ? 12 : 4"
            :class="{ 'pr-2': !isMobile }"
            style="display: flex; justify-content: center"
            class="pl-5"
          >
            <v-btn
              @click="hideOverlay"
              depressed
              large
              block
              class="px-12 py-4 primaryButton"
            >
              Abbrechen
            </v-btn>
          </v-col>
          <v-col
            :cols="isMobile ? 12 : 8"
            style="display: flex; justify-content: center"
            :class="{ 'mt-3': isMobile, 'pl-2': !isMobile }"
            class="pr-5"
          >
            <v-btn
              @click="confirmMapping"
              depressed
              large
              block
              class="px-12 py-4 primaryOnWhiteButton lightOutline"
            >
              <v-icon class="mr-4">mdi-check</v-icon> Speichern
            </v-btn>
          </v-col>
        </v-row>
        </v-container>
      </v-card>
    </v-form>
  </div>
</template>

<script>
const axios = require('axios')

export default {
  name: "JobMappingOverlay",
  props: ["jobName"],
  data() {
    return {
      selected: [],
      valid: undefined,
      jobNames: undefined,

      validObject: [
        (v) => typeof v == 'object' || !v || "Bitte Berufsbezeichnung auswählen.",
      ],
    };
  },
  methods: {
    hideOverlay() {
      this.$emit("close");
    },
    confirmMapping() {
      this.$emit("confirm", this.selected);
    },
    fetchJobNames(){
      axios.get(axios.defaults.baseURL + "jobnames/all/internals/")
      .then((resp) => {
        this.jobNames = resp.data
      })
      .catch((e) => {
        console.log(e)
      })
    }
  },
  computed: {
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        default:
          return false;
      }
    },
  },
  mounted() {
    this.selected = this.jobName.Mapping
    document.getElementById("closeButton").focus();
    this.fetchJobNames();
  },
};
</script>
