<template>
  <!-- tabindex="0"
    :style="{ 'max-height': height, height: height }" -->
  <v-card
    id="resultDetail"
    v-if="jobDescriptionData"
    class="pb-4"
    flat
    :class="{ 'pa-4': !isMobile, focusable: !isMobile }"
    :ripple="false"
  >
    <v-container
      class="scrollable"
      :class="{ hiddenScroll: isMobile, disableOverscroll: isMobile }"
    >
      <v-row style="background-color: black" class="pt-1" v-if="isMobile">
        <v-col
          cols="11"
          style="display: flex; flex-direction: column; justify-content: space-between"
        >
          <div class="pl-4">
            <h2 style="font-size: 1em" class="primaryText encodeFont">
              {{ jobDescriptionData.job_name }}
            </h2>
            <h1 style="font-size: 1.5em; color: white" class="encodeFont">
              {{ jobDescriptionData.title }}
            </h1>
          </div>
        </v-col>
        <v-col
          cols="1"
          style="transform: translate(0, -16px); display: flex; align-items: center"
        >
          <v-btn
            @click="closeDrawer"
            style="position: absolute; right: 0%; top: 0%"
            text
            class="mt-2 headerButton pt-6"
          >
            <v-icon style="color: white !important"> mdi-close </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row v-if="isEditView">
        <v-col cols="auto">
          <small>
            <i
              >Zuletzt geändert:
              {{ jobDescriptionData.last_updated | moment("DD.MM.YY") }} von
              {{
                jobDescriptionData.last_updated_by
                  ? jobDescriptionData.last_updated_by
                  : "-externe API-"
              }}</i
            >
          </small>
        </v-col>
        <v-spacer />
        <v-col cols="auto">
          <v-tooltip bottom v-if="isEditor">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                class=""
                elevation="0"
                @click="gotoCompany"
                ><v-icon style="color: var(--v-primary-base)"
                  >mdi-warehouse</v-icon
                ></v-btn
              >
            </template>
            <span>Zum Unternehmen</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                class=""
                elevation="0"
                @click="showEditView"
                ><v-icon style="color: var(--v-primary-base)">mdi-pencil</v-icon></v-btn
              >
            </template>
            <span>Bearbeiten</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon elevation="0" @click="showDeleteView"
                ><v-icon style="color: var(--v-primary-base) !important"
                  >mdi-delete</v-icon
                ></v-btn
              >
            </template>
            <span>Löschen</span>
          </v-tooltip>
          <v-tooltip
            bottom
            v-if="
              !jobDescriptionData.published &&
              jobDescriptionData.availability_end_date &&
              new Date(jobDescriptionData.availability_end_date).toLocaleString() <
                new Date().toLocaleString()
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon class="" elevation="0">
                <v-icon
                  style="color: gray !important"
                  v-if="
                    jobDescriptionData.availability_end_date &&
                    new Date(jobDescriptionData.availability_end_date).toLocaleString() <
                      new Date().toLocaleString()
                  "
                  >mdi-eye-off</v-icon
                >
              </v-btn>
            </template>
            <span>Abgelaufen </span>
          </v-tooltip>
          <v-tooltip bottom v-else>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                class=""
                elevation="0"
                @click="switchVisibility"
              >
                <v-icon
                  class="pr-2"
                  v-if="!jobDescriptionData.published"
                  style="color: var(--v-primary-base)"
                  >mdi-eye-off</v-icon
                >
                <v-icon v-else style="color: var(--v-primary-base)"
                  >mdi-eye</v-icon
                ></v-btn
              >
            </template>
            <span v-if="!jobDescriptionData.published">Einblenden</span>
            <span v-else>Ausblenden</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row class="px-0" v-if="isMobile" align="center">
        <v-col v-if="jobDescriptionData.Company" class="py-0">
          Firma: {{ jobDescriptionData.Company.name }}
        </v-col>
        <v-col v-else class="py-0"> Firma: {{ jobDescriptionData.company_name }} </v-col>
        <v-col cols="auto">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon @click="shareJD(jobDescriptionData.id)" style="width: 32px">
                <v-icon v-bind="attrs" v-on="on" color="primary"
                  >mdi-share-variant
                </v-icon>
              </v-btn>
            </template>
            <span>Stellenanzeige teilen</span>
          </v-tooltip>
        </v-col>
        <v-col v-if="resultInLocalStorage" cols="auto" @click="removeFromFavorites">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon style="width: 32px" v-bind="attrs" v-on="on" color="primary"
                >mdi-star
              </v-icon>
            </template>
            <span>Aus Favoriten entfernen</span>
          </v-tooltip>
        </v-col>
        <v-col v-else cols="auto" @click="addToFavorites">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon style="width: 32px" v-bind="attrs" v-on="on" color="primary"
                >mdi-star-outline
              </v-icon>
            </template>
            <span>Als Favorit speichern</span>
          </v-tooltip>
        </v-col>
        <v-col cols="auto" v-if="jobDescriptionData.verified">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                style="position: absolute; right: 0%"
                v-bind="attrs"
                v-on="on"
                color="primary"
                >mdi-check-circle
              </v-icon>
            </template>
            <span>Verifiziert</span>
          </v-tooltip>
        </v-col>
      </v-row>

      <v-row v-if="isMobile" class="px-0">
        <v-col>
          <v-img :src="companyLogo" style="max-height: 300px">
            <v-tooltip bottom v-if="jobDescriptionData.verified">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  style="position: absolute; right: 0%"
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
                  >mdi-check-circle
                </v-icon>
              </template>
              <span>Verifiziert</span>
            </v-tooltip>
          </v-img>
        </v-col>
      </v-row>

      <v-row v-if="!isMobile">
        <v-col :cols="4">
          <v-img :src="companyLogo" />
        </v-col>
        <v-col cols="8">
          <v-container>
            <v-row>
              <v-col
                cols="6"
                style="
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                "
              >
                <div v-if="jobDescriptionData.Company" class="py-0">
                  Firma: {{ jobDescriptionData.Company.name }}
                </div>
                <div v-else class="py-0">
                  Firma: {{ jobDescriptionData.company_name }}
                </div>
              </v-col>

              <v-spacer />

              <v-col cols="auto">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      @click="shareJD(jobDescriptionData.id)"
                      style="width: 32px"
                    >
                      <v-icon v-bind="attrs" v-on="on" color="primary"
                        >mdi-share-variant
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Stellenanzeige teilen</span>
                </v-tooltip>
              </v-col>
              <v-col v-if="resultInLocalStorage" cols="auto">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon @click="removeFromFavorites" style="width: 32px">
                      <v-icon v-bind="attrs" v-on="on" color="primary">mdi-star </v-icon>
                    </v-btn>
                  </template>
                  <span>Aus Favoriten entfernen</span>
                </v-tooltip>
              </v-col>
              <v-col v-else cols="auto">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon @click="addToFavorites" style="width: 32px">
                      <v-icon v-bind="attrs" v-on="on" color="primary"
                        >mdi-star-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Als Favorit speichern</span>
                </v-tooltip>
              </v-col>
              <v-col cols="auto" v-if="jobDescriptionData.verified" class="pt-4">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon style="width: 32px" v-bind="attrs" v-on="on" color="primary"
                      >mdi-check-circle
                    </v-icon>
                  </template>
                  <span>Verifiziert</span>
                </v-tooltip>
              </v-col>
            </v-row>

            <v-row class="">
              <v-col>
                <!-- <h2 style="font-size: 1em" class="encodeFont pb-2">Adresse</h2> -->
                <v-container fluid>
                  <v-row>
                    <v-col
                      :cols="12"
                      v-if="jobDescriptionData.company_address"
                      class="pa-0"
                      style="display: flex"
                    >
                      <v-icon class="pr-2" style="margin-left: -2px"
                        >mdi-map-marker</v-icon
                      >
                      <p class="mb-0">
                        {{ jobDescriptionData.company_address.street }},
                        {{
                          jobDescriptionData.company_address.postcode +
                          " " +
                          (jobDescriptionData.mapBox_city
                            ? jobDescriptionData.mapBox_city
                            : jobDescriptionData.company_address.city)
                        }}
                      </p>
                    </v-col>
                    <v-col :cols="12" class="pa-0" v-else style="display: flex">
                      <v-icon class="pr-2">mdi-map-marker</v-icon>
                      <p class="mb-0">
                        {{ jobDescriptionData.Company.Address.street }},
                        {{
                          jobDescriptionData.Company.Address.City.postcode +
                          " " +
                          jobDescriptionData.Company.Address.City.city
                        }}
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      class="pa-0"
                      :cols="12"
                      v-if="jobDescriptionData.weblink && jobDescriptionData.weblink"
                    >
                      <v-icon class="pr-2 negativeIconMargin">mdi-web</v-icon
                      ><a
                        v-if="
                          jobDescriptionData.weblink &&
                          jobDescriptionData.weblink != 'undefined'
                        "
                        tabindex="0"
                        @click="gotoExternal(jobDescriptionData.weblink)"
                        @keyup.enter="gotoExternal(jobDescriptionData.weblink)"
                      >
                        {{ jobDescriptionData.weblink }}</a
                      >
                      <span v-else>
                        <i>--Keine Webseite eingetragen--</i>
                      </span>
                    </v-col>
                    <v-col
                      class="pa-0"
                      :cols="12"
                      v-else-if="
                        jobDescriptionData.Company &&
                        jobDescriptionData.Company.weblink &&
                        jobDescriptionData.Company.weblink != 'null'
                      "
                    >
                      <v-icon class="pr-2 negativeIconMargin">mdi-web</v-icon>
                      <a
                        tabindex="0"
                        v-if="
                          jobDescriptionData.Company.weblink &&
                          jobDescriptionData.Company.weblink != 'undefined'
                        "
                        @click="gotoExternal(jobDescriptionData.Company.weblink)"
                        @keyup.enter="gotoExternal(jobDescriptionData.Company.weblink)"
                      >
                        {{ jobDescriptionData.Company.weblink }}</a
                      >
                      <span v-else>
                        <i>--Keine Webseite eingetragen--</i>
                      </span>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>

      <v-row v-if="!isMobile">
        <v-col cols="12">
          <div>
            <h2
              style="font-size: 1em"
              class="primaryText encodeFont"
              v-if="jobDescriptionData.job_name"
            >
              {{ jobDescriptionData.job_name }}
            </h2>
            <h2
              style="font-size: 1em"
              class="primaryText encodeFont"
              v-else-if="jobDescriptionData.Job_name"
            >
              {{ jobDescriptionData.Job_name.name }}
            </h2>
            <h1 style="font-size: 1.5em" class="encodeFont">
              {{ jobDescriptionData.title }}
            </h1>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col :cols="isMobile ? 12 : 8">
          <h2 style="font-size: 1em" class="encodeFont pb-2">Kontakt</h2>
          <v-container fluid>
            <v-row v-for="(cp, index) in jobDescriptionData.contacts" :key="index">
              <v-col :cols="12" class="pa-0">
                {{
                  (cp.first_name == undefined || cp.first_name == "undefined"
                    ? ""
                    : cp.first_name) +
                  " " +
                  cp.last_name
                }}<br />
                {{ cp.role }}<br v-if="cp.role" />
                <v-icon class="pr-2">mdi-phone</v-icon><a :id="'phoneLink_' + cp.id">--</a
                ><br />
                <v-icon class="pr-2">mdi-email</v-icon
                ><a
                  :id="'mailLink_' + cp.id"
                  :href="'mailto: &#105;&#x6E;&#102;&#111;&#64;&#x63;&#111;&#109;&#x70;&#97;&#110;&#x79;&#x2E;&#x63;&#x6F;&#x6D;'"
                  >info@company.com</a
                >
              </v-col>
            </v-row>

            <span v-if="jobDescriptionData.Company">
              <v-row
                v-for="(cp, index) in jobDescriptionData.Company.contacts"
                :key="index"
              >
                <v-col :cols="12" class="pa-0">
                  {{
                    (cp.first_name == undefined || cp.first_name == "undefined"
                      ? ""
                      : cp.first_name) +
                    " " +
                    cp.last_name
                  }}<br />
                  {{ cp.role }}
                  <v-icon class="pr-2">mdi-phone</v-icon
                  ><a :id="'phoneLink_' + cp.id">--</a><br />
                  <v-icon class="pr-2">mdi-email</v-icon
                  ><a
                    :id="'mailLink_' + cp.id"
                    :href="'mailto: &#105;&#x6E;&#102;&#111;&#64;&#x63;&#111;&#109;&#x70;&#97;&#110;&#x79;&#x2E;&#x63;&#x6F;&#x6D;'"
                    >info@company.com</a
                  >
                </v-col>
              </v-row>
            </span>
          </v-container>

          <br />
        </v-col>
        <v-spacer />
        <v-col :cols="isMobile ? 12 : 4">
          <div
            style="display: flex; justify-content: space-between; flex-direction: column"
          >
            <v-chip
              class="mb-2"
              :style="{
                'background-color':
                  jobDescriptionData.job_type == 'Praktikum'
                    ? 'var(--v-primary-base) !important'
                    : 'var(--v-accent-base) !important',
                color:
                  jobDescriptionData.job_type == 'Praktikum'
                    ? 'white !important'
                    : 'black !important',
              }"
              >{{ jobDescriptionData.job_type }}</v-chip
            >
            <v-chip class="mb-2"
              >Beginn: {{ jobDescriptionData.job_start | moment("DD.MM.YY") }}</v-chip
            >
            <v-chip v-if="jobDescriptionData.job_end"
              >Bis: {{ jobDescriptionData.job_end | moment("DD.MM.YY") }}</v-chip
            >
            <v-chip v-else>Bis: --</v-chip>
          </div>
        </v-col>
      </v-row>

      <hr class="my-3" />

      <v-row class="pb-4">
        <v-col>
          <span
            v-if="jobDescriptionData.details"
            v-html="jobDescriptionData.details"
            class="encodeFont"
          >
          </span>
          <span v-else><i>keine Stellenbeschreibung vorhanden</i> </span>

          <br />
          <br />

          <div v-if="jobDescriptionData.annotation">
            <h2 style="font-size: 1em" class="encodeFont pb-2">Anmerkung</h2>
            <span v-html="jobDescriptionData.annotation"> </span>
          </div>
        </v-col>
      </v-row>

      <v-row
        v-if="
          jobDescriptionData.external != '' && jobDescriptionData.external != undefined
        "
      >
        <v-col>
          <i
            >externe Stelle von
            <a :href="getExternalLink" target="_blank">{{ getExternalLink }}</a>
            . Für die Richtigkeit und Aktualität externer Stellenangebote sind die
            jeweiligen Stellenbörsen verantwortlich.
          </i>
        </v-col>
      </v-row>
    </v-container>

    <a tabindex="0" style="opacity: 0" @focus="backToSelectionList">zurück zur Liste</a>

    <v-snackbar v-model="copiedSnackbar" :multi-line="true" :timeout="1000" top class="pa-4">
      <div style="text-align: center; color: white; width: 100%">Kopiert!</div>
    </v-snackbar>

  </v-card>
</template>

<script>
import { permissions } from "../../util/permissions.js";

const axios = require("axios");

export default {
  name: "resultDetail",

  props: ["jobDescriptionData", "height", "isEditView", "favorites"],

  data() {
    return {
      // companyLogo: undefined,
      copiedSnackbar: false
    };
  },

  watch: {
    jobDescriptionData() {
      this.$forceUpdate();

      setTimeout(() => {
        if (this.jobDescriptionData && this.jobDescriptionData.contacts) {
          for (let i = 0; i < this.jobDescriptionData.contacts.length; i++) {
            let jdMail = document.getElementById(
              "mailLink_" + this.jobDescriptionData.contacts[i].id
            );
            if (jdMail) {
              jdMail.innerHTML = this.jobDescriptionData.contacts[i].email;
              jdMail.setAttribute("href", "mailto:" + jdMail.innerHTML);
            }

            let jdPhone = document.getElementById(
              "phoneLink_" + this.jobDescriptionData.contacts[i].id
            );

            if (jdPhone) {
              jdPhone.innerHTML = this.jobDescriptionData.contacts[i].phone;
              jdPhone.setAttribute("href", "tel:" + jdPhone.innerHTML);
            }
          }
        } else if (
          this.jobDescriptionData &&
          this.jobDescriptionData.Company &&
          this.jobDescriptionData.Company.contacts
        ) {
          for (let i = 0; i < this.jobDescriptionData.Company.contacts.length; i++) {
            let jdMail = document.getElementById(
              "mailLink_" + this.jobDescriptionData.Company.contacts[i].id
            );

            if (jdMail) {
              jdMail.innerHTML = this.jobDescriptionData.Company.contacts[i].email;
              jdMail.setAttribute("href", "mailto:" + jdMail.innerHTML);
            }

            let jdPhone = document.getElementById(
              "phoneLink_" + this.jobDescriptionData.Company.contacts[i].id
            );
            if (jdPhone) {
              jdPhone.innerHTML = this.jobDescriptionData.Company.contacts[i].phone;
              jdPhone.setAttribute("href", "tel:" + jdPhone.innerHTML);
            }
          }
        }
      }, 100);
    },
  },

  methods: {
    backToSelectionList(e) {
      this.$emit("resetFocus");
      e.preventDefault();
      e.stopPropagation();
    },
    gotoExternal(url) {
      if (!url.startsWith("https://")) {
        if (url.startsWith("http://")) {
          url = url.replace("http://", "https://");
        } else {
          url = "https://" + url;
        }
      }

      window.open(url, "_blank").focus();
    },
    switchVisibility() {
      this.$emit("hide");
    },
    showEditView() {
      this.$emit("edit");
    },
    showDeleteView() {
      this.$emit("delete");
    },
    closeDrawer() {
      this.$emit("close");
    },
    gotoCompany() {
      this.$router.push(
        "/redakteure/unternehmensprofil/" + this.jobDescriptionData.Company.name
      );
    },
    addToFavorites() {
      this.$emit("addFavorite", this.jobDescriptionData.id);
    },
    removeFromFavorites() {
      this.$emit("removeFavorite", this.jobDescriptionData.id);
    },
    shareJD(id) {
      let searchQ = btoa("?search_multi_match=&job_ids=" + id);
      if (this.$vuetify.breakpoint.mdAndDown) {
        navigator.share({
          url: this.$localURL + "#/suche/" + searchQ,
          text: "share test",
        });
      } else {
        this.copiedSnackbar = true
        navigator.clipboard.writeText(this.$localURL + "#/suche/" + searchQ);
      }
    },
  },

  computed: {
    companyLogo() {
      if (this.jobDescriptionData.company_logo) {
        if (this.jobDescriptionData.company_logo.startsWith("https://")) {
          return this.jobDescriptionData.company_logo;
        } else {
          return axios.defaults.url + this.jobDescriptionData.company_logo;
        }
      } else if (
        this.jobDescriptionData.Company &&
        this.jobDescriptionData.Company.logo
      ) {
        if (this.jobDescriptionData.Company.logo.startsWith("https://"))
          return this.jobDescriptionData.Company.logo;
        else return axios.defaults.url + this.jobDescriptionData.Company.logo;
      } else {
        return require("../../assets/images/noImg.svg");
      }
    },
    isMobile() {
      if (window.innerHeight < 750) {
        return true;
      }
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        default:
          return false;
      }
    },
    isEditor() {
      if (this.$store.state.permission <= permissions.REDAKTEUR) {
        return true;
      }
      return false;
    },
    resultInLocalStorage() {
      if (
        this.favorites &&
        this.favorites.indexOf(this.jobDescriptionData.id.toString()) != -1
      ) {
        return true;
      } else {
        return false;
      }
    },
    getExternalLink() {
      let url = this.jobDescriptionData.external;
      if (
        this.jobDescriptionData.external.startsWith("https://www.arbeitsagentur.de/") &&
        this.jobDescriptionData.ba_id
      ) {
        url +=
          "suche?id=" +
          this.jobDescriptionData.ba_id +
          "&was=" +
          this.jobDescriptionData.ba_id;
        if (this.jobDescriptionData.job_type == "Praktikum") {
          url += "&angebotsart=34";
        } else if (this.jobDescriptionData.job_type == "Ausbildung") {
          url += "&angebotsart=4";
        }
      }

      return url;
    },
  },

  mounted() {
    if (this.jobDescriptionData) {
      if (this.jobDescriptionData.contacts) {
        for (let i = 0; i < this.jobDescriptionData.contacts.length; i++) {
          let jdMail = document.getElementById(
            "mailLink_" + this.jobDescriptionData.contacts[i].id
          );
          jdMail.innerHTML = this.jobDescriptionData.contacts[i].email;
          jdMail.setAttribute("href", "mailto:" + jdMail.innerHTML);

          let jdPhone = document.getElementById(
            "phoneLink_" + this.jobDescriptionData.contacts[i].id
          );
          jdPhone.innerHTML = this.jobDescriptionData.contacts[i].phone;
          jdPhone.setAttribute("href", "tel:" + jdPhone.innerHTML);
        }
      } else {
        for (let i = 0; i < this.jobDescriptionData.Company.contacts.length; i++) {
          let jdMail = document.getElementById(
            "mailLink_" + this.jobDescriptionData.Company.contacts[i].id
          );
          jdMail.innerHTML = this.jobDescriptionData.Company.contacts[i].email;
          jdMail.setAttribute("href", "mailto:" + jdMail.innerHTML);

          let jdPhone = document.getElementById(
            "phoneLink_" + this.jobDescriptionData.Company.contacts[i].id
          );
          jdPhone.innerHTML = this.jobDescriptionData.Company.contacts[i].phone;
          jdPhone.setAttribute("href", "tel:" + jdPhone.innerHTML);
        }
      }
    }
  },
};
</script>
