<template>
  <div>
    <v-card
      :dark="false"
      style="min-height: 15vh"
      class="scrollable hiddenScroll pa-8"
      :style="{ width: cardWidth, 'max-height': cardMaxHeight }"
    >
      <v-btn
        @click="close()"
        style="position: absolute; right: 0%; top: 0%"
        text
        class="mt-2 headerButton"
        id="closeButton"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-container fluid>
        <v-row class="ma-0 pa-0">
          <v-col class="ma-0 pa-0">
            <h2 class="encodeFont">Unternehmen Akzeptieren?</h2>
          </v-col>
        </v-row>
        <br />
        <v-row class="ma-0 pa-0">
          <v-col class="ma-0 pa-0">
            <p>
              Das Unternehmen <b>{{ company.name }}</b> wird per E-Mail
              benachrichtigt und kann sich anschließend seinen Account
              aktivieren.
              <br />
              <span style="color: red"
                >Diese Aktion kann nicht rückgängig gemacht werden.</span
              >
            </p>
          </v-col>
        </v-row>
        <br v-if="companyOption" />
        <v-row class="ma-0 pa-0" v-if="companyOption">
          <v-col class="ma-0 pa-0">
            <p>
              Es existiert bereits ein Unternehmen mit gegebenem Namen und
              Adresse. Die Daten werden intern mit dem neuen Nutzeraccount
              verknüpft:
              <br />
              <b
                >{{ companyOption.name }}<br />
                {{ companyOption.Address.street }},
                {{
                  companyOption.Address.City.city +
                  " " +
                  companyOption.Address.City.postcode
                }}
              </b>
            </p>
          </v-col>
        </v-row>

        <v-row class="ma-0 pa-0">
          <v-col class="ma-0 pa-0"> Darf das Unternehmen ausbilden? </v-col>
        </v-row>

        <v-row class="ma-0 pa-0">
          <v-col class="ma-0 pa-0">
            <v-combobox
              class="maxWidthCombobox"
              hide-selected
              return-object
              :items="jobNames"
              item-text="name"
              multiple
              outlined
              small-chips
              label="Ausbildungsberufe*"
              v-model="allowedApprenticeships"
            >
              <template v-slot:selection="{ attrs, item, parent, selected }">
                <v-chip
                  v-bind="attrs"
                  :color="`${item.color} lighten-3`"
                  :input-value="selected"
                  label
                  small
                >
                  <span class="pr-2">
                    {{ item.name }}
                  </span>
                  <v-icon small @click="parent.selectItem(item)">
                    mdi-close
                  </v-icon>
                </v-chip>
              </template>
              <template v-slot:item="{ item }">
                <v-container style="max-width: 40vw !important; margin-left: 0">
                  <v-row>
                    <v-col>
                      {{ item.name }}
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </v-combobox>
          </v-col>
        </v-row>
        <br />
        <v-row class="ma-0 pa-0">
          <v-col
            class="ma-0 pa-0"
            :class="{ 'pr-2': !isMobile }"
            :cols="isMobile ? 12 : 4"
          >
            <v-btn
              class="primaryOnWhiteButton lightOutline py-3"
              large
              block
              elevation="0"
              @click="close"
            >
              <v-icon>mdi-close</v-icon> Abbrechen
            </v-btn>
          </v-col>
          <v-spacer />
          <v-col
            class="ma-0 pa-0"
            :class="{ 'pl-2': !isMobile }"
            :cols="isMobile ? 12 : 8"
          >
            <v-btn
              class="primaryButton pa-1 py-3"
              @click="confirm"
              :class="{ 'mt-3': isMobile }"
              elevation="0"
              large
              block
            >
              <v-icon>mdi-check</v-icon> Fortfahren
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <div tabindex="0" @focus="resetFocus" style="height: 0px; opacity: 0">
      Fokus zurück zu "Schließen"
    </div>
  </div>
</template>

<script>
const axios = require("axios");

export default {
  name: "acceptRequest",

  props: ["company"],

  data() {
    return {
      companyOption: undefined,
      allowedApprenticeships: [],
      jobNames: []
    };
  },

  computed: {
    cardWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "85vw";
        case "sm":
          return "75vw";
        case "md":
          return "65vw";
        case "lg":
          return "50vw";
        case "xl":
          return "50vw";
        default:
          return "65vw";
      }
    },
    cardMaxHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "85vh";
        case "sm":
          return "75vh";
        case "md":
          return "65vh";
        case "lg":
          return "50vh";
        case "xl":
          return "50vh";
        default:
          return "65vh";
      }
    },
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        default:
          return false;
      }
    },
  },

  methods: {
    resetFocus(e) {
      e.stopPropagation();
      e.preventDefault();

      document.getElementById("closeButton").focus();
    },
    close() {
      this.$emit("close");
    },
    confirm() {
      this.$emit("confirm", { id: this.company.id, allowedApprenticeships: this.allowedApprenticeships });
    },
  },

  mounted() {
    axios
      .get(
        axios.defaults.baseURL +
          "companies/by/name/?name=" +
          this.company.name.replaceAll("&", "%26") +
          "&street=" +
          this.company.street +
          "&city=" +
          this.company.City.id
      )
      .then((resp) => {
        this.companyOption = resp.data[0];
      });

      axios
        .get(axios.defaults.baseURL + "jobnames/all/?filter=1")
        .then((resp) => {
          this.jobNames = resp.data;
        })
        .catch((e) => {
          window.dispatchEvent(new CustomEvent("showErrorDisplay"));
          console.log(e);
        });

    document.getElementById("closeButton").focus();
  },
};
</script>
