<template>
  <div>
    <v-card
      class="scrollable hiddenScroll"
      :style="{
        width: component ? '100%' : cardWidth,
        height: cardHeight != undefined ? cardHeight : 'auto',
        'min-height': component ? 'unset' : '30vh',
        'max-height': cardHeight == 'auto' ? 'unset' : '80vh',
      }"
      :class="{ 'pa-8': !setupView && !component, 'pa-3': component, 'pt-1': component }"
      :flat="component"
    >
      <v-btn
        v-if="!setupView && !component"
        @click="close()"
        style="position: absolute; right: 0%; top: 0%"
        text
        class="mt-2 headerButton"
        id="closeButton"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <v-form ref="contactForm">
        <v-container :class="{ 'pa-0': component }" fluid>
          <v-row class="ma-0 pa-0" v-if="!component">
            <v-col class="ma-0 pb-4 pt-0">
              <h2 class="encodeFont">Kontaktperson</h2>
            </v-col>
          </v-row>
          <v-row v-if="setupView" class="ma-0 pa-0">
            <v-col>
              <p>
                Dieser Kontakt wird für alle potenziellen Bewerber sichtbar in den
                Stellenanzeigen erscheinen.
              </p>
            </v-col>
          </v-row>
          <v-row class="ma-0 pa-0">
            <v-col :cols="isMobile ? 12 : 6" class="py-0"  :class="{'pl-0': component}">
              <v-text-field
                label="Rolle"
                v-model="role"
                :rules="[max30]"
                outlined
                @input="emitChange()"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="ma-0 pa-0">
            <v-col class="py-0" :cols="isMobile ? 12 : 6"  :class="{'pl-0': component}">
              <v-text-field
                label="Vorname"
                v-model="firstName"
                :rules="[max20]"
                outlined
                @input="emitChange()"
              ></v-text-field>
            </v-col>
            <v-col class="py-0" :cols="isMobile ? 12 : 6"  :class="{'pr-0': component}">
              <v-text-field
                label="Nachname*"
                :rules="[required, max30]"
                v-model="lastName"
                outlined
                @input="emitChange()"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="ma-0 pa-0">
            <v-col class="py-0" :cols="isMobile ? 12 : 6"  :class="{'pl-0': component}">
              <v-text-field
                label="Telefon*"
                :rules="[required, max20]"
                v-model="phone"
                outlined
                @input="emitChange()"
              ></v-text-field>
            </v-col>
            <v-col class="py-0" :cols="isMobile ? 12 : 6"  :class="{'pr-0': component}">
              <v-text-field
                label="E-Mail*"
                :rules="[max50, required]"
                v-model="email"
                outlined
                @input="emitChange()"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="ma-0 pa-0" v-if="!setupView && !component">
            <v-spacer />
            <v-col class="py-0" :cols="isMobile ? 12 : 4">
              <v-btn
                :class="{ 'py-1': isMobile }"
                block
                elevation="0"
                class="primaryOnWhiteButton lightOutline py-3"
                @click="close()"
                ><v-icon class="mr-4">mdi-cancel</v-icon> Abbrechen
              </v-btn>
            </v-col>
            <v-spacer />
            <v-col class="py-0" :class="{ 'mt-3': isMobile }" :cols="isMobile ? 12 : 8">
              <v-btn block elevation="0" class="primaryButton py-3" @click="saveData()"
                ><v-icon class="mr-4">mdi-check</v-icon> Speichern
              </v-btn>
            </v-col>
            <v-spacer />
          </v-row>
        </v-container>
      </v-form>

      <div
        v-if="!component"
        tabindex="0"
        @focus="resetFocus"
        style="height: 0px; opacity: 0"
      >
        Fokus zurück zu "Schließen"
      </div>
    </v-card>

    <v-overlay :value="loading" v-if="!component">
      <v-progress-circular indeterminate color="accent"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
const axios = require("axios");

export default {
  name: "contactEdit",

  props: ["contactData", "setupView", "cardHeight", "updateNow", "component"],

  data() {
    return {
      role: undefined,
      firstName: undefined,
      lastName: undefined,
      phone: undefined,
      email: undefined,
      id: undefined,

      required: (value) => !!value || "Pflichtfeld!",
      max30: (value) => !value || value.length <= 30 || "Max. 30 Zeichen!",
      max20: (value) => !value || value.length <= 20 || "Max. 20 Zeichen!",
      max50: (value) => !value || value.length <= 50 || "Max. 50 Zeichen!",

      loading: false,
    };
  },

  components: {},

  computed: {
    cardWidth() {
      if(this.component){
        return "unset"
      }

      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "85vw";
        case "sm":
          return "75vw";
        case "md":
          return "65vw";
        case "lg":
          return "55vw";
        case "xl":
          return "45vw";
        default:
          return "65vw";
      }
    },
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        default:
          return false;
      }
    },
  },

  watch: {
    contactData() {
      this.updateData();
    },
    updateNow() {
      this.saveData();
    },
  },

  methods: {
    emitChange() {
      if (this.component) {
        this.$emit("change", {
          first_name: this.firstName,
          last_name: this.lastName,
          phone: this.phone,
          email: this.email,
          role: this.role ? this.role : "",
          id: this.contactData ? this.contactData.id : undefined
        });
      }
    },
    resetFocus(e) {
      e.preventDefault();
      e.stopPropagation();
      document.getElementById("closeButton").focus();
    },
    close(data) {
      this.$emit("close", data);
    },

    saveData() {
      ++this.validateNow;

      setTimeout(() => {
        if (this.$refs.contactForm.validate()) {
          this.loading = true;

          let formData = {
            first_name: this.firstName,
            last_name: this.lastName,
            phone: this.phone,
            email: this.email,
            role: this.role ? this.role : "",
          };

          if (this.contactData && this.contactData.id) {
            formData["id"] = this.contactData.id;
            axios
              .patch(
                axios.defaults.baseURL + "contactpersons/patch/" + this.contactData.id,
                formData
              )
              .then((resp) => {
                this.id = resp.data.id;

                this.loading = false;
                this.close(resp.data);
              })
              .catch((e) => {
                this.loading = false;

                window.dispatchEvent(new CustomEvent("showErrorDisplay"));

                console.log(e);
                this.$emit("error");
              });
          } else {
            axios
              .post(axios.defaults.baseURL + "contactpersons/post/", formData)
              .then((resp) => {
                this.id = resp.data.id;

                this.loading = false;
                this.close(resp.data);
              })
              .catch((e) => {
                this.loading = false;

                window.dispatchEvent(new CustomEvent("showErrorDisplay"));
                console.log(e);
                this.$emit("error");
              });
          }
        } else {
          this.loading = false;
          this.$emit("error");
        }
      }, 250);
    },
    updateData() {
      if (this.contactData) {
        this.role = this.contactData.role;
        this.firstName = this.contactData.first_name;
        this.lastName = this.contactData.last_name;
        this.phone = this.contactData.phone;
        this.email = this.contactData.email;
        this.id = this.contactData.id;
      }
    },
  },

  mounted() {
    this.updateData();

    try {
      document.getElementById("closeButton").focus();
    } catch (e) {
      console.log("close button not loaded...", e);
    }
  },
};
</script>
