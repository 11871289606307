<template>
  <div id="ownJD" style="width: 100%">
    <v-container fluid style="margin: 0 auto">
      <v-row>
        <v-col :cols="6" class="pa-0">
          <v-card flat>
            <v-card-title class="encodeFont"
              ><v-icon class="mr-2">mdi-file-document-multiple</v-icon>
              Stellenanzeigen</v-card-title
            >
          </v-card>
        </v-col>
        <v-spacer />
        <v-col cols="auto" class="pa-0 pr-3">
          <v-btn
            @click="openDeletionWarning()"
            large
            block
            elevation="0"
            class="primaryOnWhiteButton pa-0 plusButton"
            ><v-icon> mdi-trash-can</v-icon>
          </v-btn>
        </v-col>

        <v-col cols="auto" class="pa-0">
          <v-btn
            @click="openEditView()"
            large
            block
            elevation="0"
            class="primaryOnWhiteButton pa-0 plusButton"
            ><v-icon> mdi-plus</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row v-if="!jobDescriptions || jobDescriptions.length == 0">
        <v-col class="px-0">
          <v-card flat>
            <p class="pa-16">Keine Stellenanzeigen vorhanden!</p>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-else>
        <v-col
          :cols="isMobile ? 12 : 5"
          class="px-0 scrollable hiddenScroll mt-3"
          style="padding-top: 0px; padding-bottom: 0px"
          :style="{
            'max-height': isMobile ? 'unset' : '80vh',
            'min-height': isMobile ? 'unset' : '80vh',
            height: isMobile ? 'unset' : '80vh',
          }"
        >
          <div v-for="(j, index) in jobDescriptions" :key="j.id" flat class="mb-3">
            <resultCard
              :showPublished="true"
              :result="j"
              :id="'resultCard_' + j.id"
              @switchFocus="switchFocus"
              @click="showDetailView(j)"
            />
            <div
              v-if="index == jobDescriptions.length - 1"
              tabindex="0"
              @focus="rerouteFocus"
              style="opacity: 0; height: 0px"
            >
              Tastatursteuerung Hilfsanker Start
            </div>
          </div>
        </v-col>
        <v-col cols="7" v-if="!isMobile" class="pr-0">
          <resultDetail
            id="resultDetail"
            :jobDescriptionData="activeJD"
            :height="'80vh'"
            :isEditView="true"
            @edit="openEditView(activeJD)"
            @delete="deletionWarningMenu = true"
            @hide="switchVisibility"
            @resetFocus="resetFocus"
          />
        </v-col>
      </v-row>
    </v-container>

    <v-overlay :value="deletionWarningMenu" :dark="false" style="z-index: 12">
      <deletionWarning
        v-if="activeJD"
        :data="activeJD.title"
        item=" die Stellenanzeige"
        :additional="'Die Stellenanzeige wird für alle Standorte gelöscht!'"
        @cancel="deletionWarningMenu = false"
        @confirm="deleteJD"
      />
    </v-overlay>

    <v-overlay :value="showEditMenu" :dark="false" style="z-index: 12">
      <jdEdit
        :jdData="editedJD"
        :companies="companyData"
        @close="closeEditView"
        :refreshDisplay="refreshEdit"
      />
    </v-overlay>

    <resultDetailMobile
      :jobDescriptionData="activeJD"
      :height="'100vh'"
      :open="showMobileJDDetail"
      :companies="companyData"
      :editorView="true"
      :isEditView="true"
      @closeDrawer="showMobileJDDetail = 0"
      @edit="openEditView(activeJD)"
      @delete="deletionWarningMenu = true"
      @hide="switchVisibility"
    />

    <v-overlay :value="loading">
      <v-progress-circular indeterminate color="accent"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
const axios = require("axios");

import resultCard from "../../components/JobDescriptions/ResultCard_Backoffice.vue";
import resultDetail from "../../components/JobDescriptions/ResultDetail_Backoffice.vue";

import deletionWarning from "../../components/Main/DeletionWarning.vue";
import jdEdit from "../../components/Backoffice/JobDescription/JobDescriptionEdit.vue";

import resultDetailMobile from "../../components/JobDescriptions/ResultDetail_MobileDrawer_Backoffice.vue";

export default {
  name: "jobDescriptions",

  data() {
    return {
      jobDescriptions: [],
      activeJD: undefined,
      editedJD: undefined,

      deletionWarningMenu: false,
      showEditMenu: false,

      showMobileJDDetail: 0,

      companyData: undefined,

      loading: false,

      refreshEdit: 0,
    };
  },

  components: {
    resultCard,
    resultDetail,

    deletionWarning,
    jdEdit,

    resultDetailMobile,
  },

  computed: {
    maxWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "100vw";
        case "sm":
          return "90vw";
        case "md":
          return "85vw";
        case "lg":
          return "75vw";
        case "xl":
          return "65vw";
        default:
          return "50vw";
      }
    },

    headerCol() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 6;
        case "sm":
          return 7;
        case "md":
          return 9;
        case "lg":
          return 9;
        case "xl":
          return 10;
        default:
          return 10;
      }
    },
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        default:
          return false;
      }
    },
  },

  methods: {
    rerouteFocus(e) {
      document.getElementById("datenschutzLink").focus();
      e.stopPropagation();
      e.preventDefault();
    },
    switchFocus() {
      document.getElementById("resultDetail").focus();
    },
    resetFocus() {
      for (let i = 0; i < this.jobDescriptions.length; i++) {
        if (this.jobDescriptions[i].id == this.activeJD.id) {
          if (i < this.jobDescriptions.length - 1) {
            document
              .getElementById("resultCard_" + this.jobDescriptions[i + 1].id)
              .focus();
          } else {
            document.getElementById("resultCard_" + this.jobDescriptions[0].id).focus();
          }

          return;
        }
      }
    },
    closeEditView(data) {
      this.showEditMenu = false;


      if (data) {
        this.getJDData(data.resp_id);
      }
    },
    showDetailView(jd) {
      this.activeJD = jd;

      if (this.isMobile) {
        this.showMobileJDDetail++;
      }
    },
    openEditView(jd) {
      this.editedJD = jd;

      this.showEditMenu = true;
      this.refreshEdit++;
    },
    openDeletionWarning() {
      this.deletionWarningMenu = true;
    },
    deleteJD() {
      axios
        .delete(axios.defaults.baseURL + "jobdescriptions/delete/" + this.activeJD.id)
        .then(() => {
          this.getJDData();

          this.deletionWarningMenu = false;
        })
        .catch((e) => {
          console.log(e);
          window.dispatchEvent(new CustomEvent("showErrorDisplay"));
        });
    },
    switchVisibility(jd) {
      this.loading = true;

      console.log(jd);

      let publishDate;

      if (!jd.published) {
        let temp = new Date().toLocaleString().split(",")[0].split(".");
        publishDate = temp[2] + "-" + temp[1] + "-" + temp[0];
      }

      axios
        .patch(axios.defaults.baseURL + "jobdescriptions/visibility/patch/" + jd.id, {
          id: jd.id,
          published: !jd.published,
          publish_date: publishDate,
        })
        .then(() => {
          jd.published = !jd.published;

          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
          window.dispatchEvent(new CustomEvent("showErrorDisplay"));
        });
    },
    getJDData(id) {
      axios
        .get(axios.defaults.baseURL + "jobdescriptions/own")
        .then((resp) => {
          this.jobDescriptions = resp.data;

          this.activeJD = this.jobDescriptions[0];
          if (id) {
            for (let i = 0; i < this.jobDescriptions.length; i++) {
              if (this.jobDescriptions[i].id == id) {
                this.activeJD = this.jobDescriptions[i];
                break;
              }
            }
          }

          this.getCompanyData();
        })
        .catch((e) => {
          console.log(e);
          window.dispatchEvent(new CustomEvent("showErrorDisplay"));
        });
    },

    getCompanyData() {
      axios
        .get(axios.defaults.baseURL + "companies/simple/own")
        .then((resp) => {
          this.companyData = resp.data;
        })
        .catch((e) => {
          window.dispatchEvent(new CustomEvent("showErrorDisplay"));
          console.log(e);
        });
    },
  },

  mounted() {
    this.getJDData();
  },
};
</script>
